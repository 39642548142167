<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Crear Usuario</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Usuario</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="username" class="form-label form-label-novo">Nombre <span class="obligatory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="255"
                  v-model="name"
                  placeholder="Nombre del Usuario"
                />           
              </div>
              <div class="form-group col-md-4">
                <label for="firstname" class="form-label form-label-novo">Apellidos <span class="obligatory">*</span></label>
                <input
                id="firstname"
                type="text"
                name=""
                class="form-control"
                maxlength="255"
                v-model="firstname"
                placeholder="Apellidos"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="initials" class="form-label form-label-novo">Iniciales <span class="obligatory">*</span></label>
                <input
                id="initials"
                type="text"
                name=""
                class="form-control"
                v-model="initials"
                placeholder="Iniciales"
                maxlength="3"
                />
              </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                <label for="email" class="form-label form-label-novo">Correo electrónico <span class="obligatory">*</span></label>
                <input
                    id="email"
                    type="email"
                    name=""
                    class="form-control"
                    v-model="email"
                    placeholder="Correo electrónico"
                />
                </div>
                <div class="form-group col-md-6">
                <label for="pass" class="form-label form-label-novo">Contraseña <span class="obligatory">*</span></label>
                <input
                id="pass"
                type="password"
                name=""
                class="form-control"
                v-model="password"
                placeholder="Contraseña"
                />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                <label for="phone" class="form-label form-label-novo">Teléfono</label>
                <input
                    id="phone"
                    type="text"
                    name=""
                    class="form-control"
                    v-model="phone"
                    placeholder="Teléfono"
                />
                </div>
                <div class="form-group col-md-4">
                <label for="age" class="form-label form-label-novo">Edad</label>
                <input
                    id="age"
                    type="number"
                    name=""
                    class="form-control"
                    v-model="age"
                    placeholder="Edad"
                />
                </div>
                <div class="form-group col-md-4">
                <label for="gender" class="form-label form-label-novo">Género</label>
                <select class="form-control" v-model="gender">
                  <option value="">Seleccione un elemento</option>
                  <option value="0">M</option>
                  <option value="1">H</option>
                </select>
                </div>
            </div>
            <div class="form-row" align="center">
                <div class="form-group col-md-12">
                <label for="isAdmin" class="form-label form-label-novo">Tipo de usuario</label>
                <br>
                <input type="radio" id="isAdmin1" value="0" v-model="isAdmin">
                <label for="isAdmin1">Jugador</label>
                <br>
                <input type="radio" id="isAdmin2" value="1" v-model="isAdmin">
                <label for="isAdmin2">Adminisitrador</label>
                </div>
            </div>
            <button 
              @click="handleAddUser()"
              type="button" class="btn btn-primary">Guardar cambios</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  data: () => {
    return {
      name: "",
      firstname: "",
      initials: "",
      email: "",
      password: "",
      phone: "",
      age: "",
      gender: "",
      isAdmin: "",
    };
  },
  validations: {
    name: { required },
    firstname: { required },
    initials: { required },
    email: { required, email },
    password: { required },
  },
  methods: {
    ...mapActions(['addUser']),
    async handleAddUser(){
      if (!this.$v.$invalid) {
        this.$fire({
            text: "Espera un momento.",
            showCancelButton: false,
            showConfirmButton: false
        });
        const res = await this.addUser({
            name: this.name,
            firstname: this.firstname,
            initials: this.initials,
            email: this.email,
            password: this.password,
            phone: this.phone,
            age: this.age,
            gender: this.gender,
            isAdmin: this.isAdmin,
        });
        if(res){
          this.$alert("Usuario Creado.");
          this.$router.push({
                name: "dashboardusers",
            });
        } else {
            console.error("Algo falló");
        }
      }else{
        this.$alert("Debes llenar los campos marcados con *.");
      }
    }
  },
};
</script>