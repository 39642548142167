<template>
  <footer class="d-flex justify-content-around py-4 fixed-bottom footer-novo">
    <img class="logo-footer-shop img-fluid" src="../../assets/img/house2.svg" alt="">
    <div class="topuser-footer-shop">
      <p class="text-faileasy-description mb-0 "> TOP </p>
      <a @click.prevent="handleGoToTop" href="#">
      <img class="img-fluid trophy-footer-shop" src="../../assets/img/btncup.svg" alt="">
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  methods:{
    handleGoToTop(){
      this.$router.push('/score');
    }
  }
}
</script>
<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "node_modules/bootstrap/scss/grid";
@import "../../assets/css/colors";

.logo-footer-shop{
  width: 100%;
}
.trophy-footer-shop{
  width: 100%;
}
.topuser-footer-shop{
  position: absolute;
  right: -8%;
  bottom: 15%;
}
@include media-breakpoint-up(xs) { 
  .footer-novo{
    position: inherit;
  }
  .topuser-footer-shop{
    right: 3.5%;
    bottom: 29%;
  }
}
@include media-breakpoint-up(md) { 
  .topuser-footer-shop{
    right: 5%;
    bottom: 26.5%;
  }
}
@include media-breakpoint-up(lg) { 
  .topuser-footer-shop{
    right: 5%;
    bottom: 27%;
  }
}
@include media-breakpoint-up(xl) {
  .topuser-footer-shop{
    right: 5%;
    bottom: 26%;
  }
}
</style>