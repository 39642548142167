<template>
  <quiz-layout :bgColorLevel="cssclasback">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>
    <template v-slot:titleContent>
      <section class="header-quiz row">
        <div class="offset-2 col-7 text-center">
          <h1 class="pt-2">{{ category.name }}</h1>
          <h1 class="pb-2">{{ category.points }} Coronas</h1>
        </div>
        <div
          class="col-3 d-flex align-items-center justify-content-center text-center"
        >
          <progress-game :counter="timerNovo" />
        </div>
      </section>
    </template>
    <template v-slot:mainContent>
      <quiz
        :bgColorLink="cssclasitem"
        :bgColorLevel="cssclasback"
        @handleTimerInit="initializeTimer"
      />
    </template>
    <template v-slot:footerContent>
      <footer-alt :bgimage="'bg-image-quiz'"></footer-alt>
    </template>
  </quiz-layout>
</template>

<script>
import QuizLayout from "./layouts/QuizLayout";
import HeaderGame from "./shared/Header";
import FooterAlt from "./shared/FooterAlt";
import Quiz from "./GameComponents/Quiz";
import ProgressGame from "./GameComponents/Progress";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    QuizLayout,
    HeaderGame,
    FooterAlt,
    Quiz,
    ProgressGame,
  },
  computed: {
    ...mapState({
      category: (state) => state.game.categorySlctd,
      settings: (state) => state.game.settingsGame,
    }),
    cssclasback() {
      if (this.category.name.toLowerCase() === "fácil") return "facil";
      else return this.category.name.toLowerCase();
    },
    cssclasitem() {
      if (this.category.name.toLowerCase() === "fácil") return "facil-link";
      else return `${this.category.name.toLowerCase()}-link`;
    },
  },
  data() {
    return { timerNovo: 0 };
  },
  methods: {
    ...mapActions(["updateTimeFinished"]),
    handleTimeToResponse(killInterval = false) {
      if (!killInterval) {
        const timer = setInterval(() => {
          this.timerNovo--;
          if (this.timerNovo <= 0) {
            this.updateTimeFinished(true);
            clearInterval(timer);
            this.$router.push("/fail");
          }
        }, 1000);
      }
    },
    handleSelectedtAns() {
      // Kill timeout
      for (let i = 0; i < 10000; i++) {
        window.clearInterval(i);
      }
    },
    initializeTimer(){
      this.handleTimeToResponse();
    }
  },
  mounted() {
    // TODO: hacer un get de settings
    this.timerNovo = this.settings.timeForResponse;
  },
  destroyed(){
    this.handleSelectedtAns();
  }
};
</script>