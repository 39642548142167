<template>
  <div class="content-clock">
    <span class=""> {{counter}} </span>
  </div>
</template>
<script>
export default {
  props: {
    counter : Number,
  },
}
</script>