  
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

import GameModule from "./game";
import AuthModule from "./auth";
import ShopModule from "./shop";
import DashboardModule from "./dashboard";
import DashboardProducts from "./dashboardproducts";
import DashboardTokens from "./dashboardtokens";
import DashboardTransactions from "./dashboardtransactions";
import DashboardUsers from "./dashboardusers";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: { 
    game: GameModule, 
    auth: AuthModule, 
    shop: ShopModule, 
    dash: DashboardModule, 
    dashboardproducts: DashboardProducts,
    dashboardtokens: DashboardTokens,
    dashboardtransactions: DashboardTransactions,
    dashboardusers: DashboardUsers,
  },
  state: { 
    isLoading: false
  },
  plugins: [
    createPersistedState({
      paths: [ 'auth', 'game', 'dash', 'dashboardtokens', 'dashboardtransactions', 'dashboardusers']
    })
  ],
  getters: { },
  mutations: { 
    setLoading(state, isLoading){
      state.isLoading = isLoading
    },
  },
  actions: { 
    setLoadingState(context, isLoading){
      context.commit('setLoading', isLoading);
    }
  }
});