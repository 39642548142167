<template>
  <transition name="modal">
    <div class="modal-mask" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="content-close-modal">
            <a @click="$emit('close')">
              <vue-fontawesome 
                class="ml-3" 
                icon="times-circle" 
                size="4" 
                :style="{ color: '#003878' }"></vue-fontawesome>
            </a>
          </div>
          <div class="modal-body">
            <slot name="body">
              <video width="370" height="660" controls>
                <source
                  :src="require(`@/assets/welcomeToNovo.mp4`)"  >
                Your browser does not support the video tag.
              </video>
             
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  props:{
    showModal: Boolean
  },
  mounted(){
  }
}
</script>
<style lang="scss" >
@import "../../assets/css/colors";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 440px;
  width: 370px;
  height: 660px;
  margin: 0px auto;
  // padding: 20px 30px;
  background-color: $color-primary;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.content-close-modal{
  position: relative;
  a{
    position: absolute;
    right: -25px;
    top: -18px;
    z-index: 1;
    cursor: pointer;
  }
}
.modal-body {
  // margin: 20px 0;
  padding: 0;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>