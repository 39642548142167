<template>
  <score-layout :bgColorLevel="'bg-intermediete-level'">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>
    <template v-slot:titleContent>
      <section class="d-flex wrapper-head-score bg-card-1 justify-content-between">
        
          <h1 class="text-uppercase">Top Ten</h1>
        <!-- </div>
        <div> -->
          <h5>Semana 1</h5>
        <!-- </div> -->
      </section>
    </template>
    <template v-slot:mainContent v-if="loaded">
      <section class="score-section text-center">
        <table class="table table-striped">
          <tbody>
            <tr v-for="(top, index) in topUsers" :key="top.id">
              <td class="content-avatar-item v-middle">
                <div class="content-score d-flex justify-content-end">
                  <img src="../assets/img/avatar.svg" alt="" />
                </div>
              </td>
              <td>
                <div class="top-number-item">
                  <h1 class="txt-color-card-2 mb-0">{{ ++index }}°</h1>
                  <h5 class="txt-color-white">
                    {{ top.name }} {{ top.firstName }}
                  </h5>
                </div>
              </td>
              <td class="v-middle">
                  <img src="../assets/img/play.svg" alt="" />
              </td>
              <td class="v-middle"> <h1 class="crowns-number">{{ top.points }}</h1> </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-5 pb-4">
          <button
            type="button"
            @click.prevent="handleGoToStart"
            class="btn btn-primary-novo text-uppercase"
          >
            Regresar al juego
          </button>
        </div>
      </section>
    </template>
    <template v-slot:footerContent>
      <footer-alt></footer-alt>
    </template>
  </score-layout>
</template>

<script>
import ScoreLayout from "./layouts/ScoreLayout";
import HeaderGame from "./shared/Header";
import FooterAlt from "./shared/FooterAlt";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    ScoreLayout,
    HeaderGame,
    FooterAlt,
  },
  computed: {
    ...mapState({
      topUsers: (state) => state.game.topten,
    }),
    loaded() {
      return this.topUsers.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions(["getTopUsers"]),
    handleGoToStart() {
      // this.updateShowAssitant(true);
      this.$router.push("/start");
    },
  },
  mounted() {
    this.getTopUsers(10);
  },
};
</script>