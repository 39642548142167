<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Configuraciones Generales</h1>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Configuraciones</h2>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-group">
              <label for="initialMessagge">Mensaje incial del juego</label>
              <input
                type="text"
                class="form-control"
                id="initialMessagge"
                v-model="settingsEdit.initialMessage"
                placeholder="Mensaje incial"
              />
              <small id="initialMessagge" class="form-text text-muted">Este mensaje lo muestra el asistente del juego al inicio.</small>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="multiplyFor">Multiplicar puntos por:</label>
                <select 
                  v-model="settingsEdit.multiplyPoints"
                  id="multiplyFor" 
                  class="form-control">
                  <option :selected="settingsEdit.multiplyPoints == 1" value=1>1</option>
                  <option :selected="settingsEdit.multiplyPoints == 2" value=2>2</option>
                  <option :selected="settingsEdit.multiplyPoints == 3" value=3>3</option>
                  <option :selected="settingsEdit.multiplyPoints == 4" value=4>4</option>
                </select>
                <small id="multiplyFor" class="form-text text-muted">Los puntos de cada categoría se multiplicaran por la opción seleccionada.</small>
              </div>
              <div class="form-group col-md-6">
                <label for="timeForResponse">Tiempo para responder una pregunta</label>
                <div class="input-group">
                  <input
                      v-model="settingsEdit.timeForResponse"
                      type="number"
                      class="form-control"
                      id="timeForResponse"
                      aria-describedby="basic-addon2"
                    />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Segundos</span>
                  </div>
                </div>
                
                
                <small id="timeForResponse" class="form-text text-muted">El tiempo es dado en segundos</small>
              </div>
            </div>
             <button 
              @click="handleSaveChanges()"
              type="button" class="btn btn-primary">Guardar cambios</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  computed: {
    ...mapState({
      settings: (state) => state.dash.settingsGame,
    }),
  },
  data() {
    return {
      settingsEdit: {},
    };
  },
  methods: {
    ...mapActions(['getSettingsGame', 'updateSettingsGame']),
    async handleSaveChanges(){
      const res = await this.updateSettingsGame(this.settingsEdit);
      if(res){
        this.$alert("Configuración Actualizada.");
      }else{
        this.$alert("Algo ha fallado, intenta mas tarde.");
      }
    }
  },
  async mounted() {
    await this.getSettingsGame();
    this.settingsEdit = Object.assign({}, this.settings);
  },
};
</script>