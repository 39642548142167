<template>
  <section id="top5" class="page-section site-top-five portfolio py-5">
    <div class="container mt-5" align="center">
      <!-- Portfolio Section Heading-->
      <h2 class="page-section-heading text-center mt-5 mb-0 pt-4">
        Top 5 <br />
        de la semana
      </h2>
      <span style="color: #d4e8f8">
        <vue-fontawesome class="ml-3" icon="star" size="4" color="lightgray"></vue-fontawesome>
        <vue-fontawesome class="ml-3" icon="star" size="4" color="lightgray"></vue-fontawesome>
        <vue-fontawesome class="ml-3" icon="star" size="4" color="lightgray"></vue-fontawesome>
        <vue-fontawesome class="ml-3" icon="star" size="4" color="lightgray"></vue-fontawesome>
        <vue-fontawesome class="ml-3" icon="star" size="4" color="lightgray"></vue-fontawesome>
      </span>
    </div>
    <div class="container-fluid img-footer-site">
      <div class="row">
        <div class="col-md-12 button-container2" align="center">
          <br />
          <carousel 
          v-if="loaded"
          :margin='30'
          :items='1'
          :nav="false"
          :dots="false"
          :animateIn="true"
          :autoplay="true"
          :loop="true" >
            <div v-for='(top, index) in topUsers' 
            v-bind:key='top.id'
              class="testimonial d-flex justify-content-center">
              <div class="testimonial-content">
                <h2 class="page-section-heading text-center mb-4">{{top.points}}</h2>
                <img class="img-responsive-novo mb-4"  :src="require(`@/assets/avatars/${top.avatar}`)"  />
                <div class="header-top-2 mt-4">
                  <h2 class="page-section-heading"><i>{{++index}}°</i></h2>
                  {{top.name}}
                </div>
              </div>
            </div>
          </carousel>
           
        </div>
      </div>
      <div class="row mt-5">
        <div
          class="col-md-12 mt-4 d-flex justify-content-center"
        >
          <router-link to="/login" class="btn btn-primary-novo text-uppercase">Jugar</router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapState } from "vuex";
import carousel from 'vue-owl-carousel2';

export default {
  components: {
    carousel
  },
  computed:{
    ...mapState({ 
      topUsers: (state) => state.game.topten,
    }),
    loaded() {
      return this.topUsers.length > 0 ? true : false
    }
  },
  methods: {
    ...mapActions(['getTopUsers']),
  },
  mounted() {
    this.getTopUsers(5);
    console.log(this.topUsers);
  },
}
</script>
<style lang="scss">
</style>