import Vue from "vue";
import VueRouter  from "vue-router";

import Authentication from '../components/Authentication';
import Register from '../components/Register';
import StartGame from '../components/StartGame';
import NovoSite from '../components/NovoSite';

import SaleComplete from '../components/SaleComplete';
import ShopDetails from '../components/ShopDetails';
import Shop from '../components/Shop';
import CorrectResult from '../components/CorrectResult';
import FailResult from '../components/FailResult';
import NovoGame from '../components/NovoGame';
import Score from '../components/Score';
import SuccessRegister from '../components/SuccessRegister';
import NovoAdmin from '../components/NovoAdmin';

import MainQuestion from '../components/Dashboard/MainQuestion';
import LoadQuestion from '../components/Dashboard/Questions/LoadQuestion';
import Detail from '../components/Dashboard/Questions/Detail';
import SettingsGame from '../components/Dashboard/SettingsGame';
import Privacity from '../components/Privacity';
import Profile from '../components/Profile';
import UploadQuestion from '../components/Dashboard/Questions/Upload';

import MainProducts from '../components/Dashboard/MainProducts';
import DetailProduct from '../components/Dashboard/Products/Detail';
import CreateProduct from '../components/Dashboard/Products/Create';
import UploadProduct from '../components/Dashboard/Products/Upload';

import MainTokens from '../components/Dashboard/MainTokens';

import MainTransactions from '../components/Dashboard/MainTransactions';
import DetailTransactions from '../components/Dashboard/Transactions/Detail';

import MainUsers from '../components/Dashboard/MainUsers';
import DetailUsers from '../components/Dashboard/Users/Detail';
import CreateUsers from '../components/Dashboard/Users/Create';

import dataStore from '../store';

Vue.use(VueRouter);

let router =  new VueRouter({
  mode: 'hash',
  // mode:'history',
  routes:[
    {
      path: '/',  name: 'home', component: NovoSite,
    },
    // {
    //   path: '/',  name: 'login', component: Authentication
    // },
    {
      path: '/start', name: 'start', component: StartGame,
      meta: { requiresAuth: true }
    },
    {
      path: '/profile', name: 'profile', component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: '/quiz',  name: 'quiz', component: NovoGame,
      meta: { requiresAuth: true }
    },
    {
      path: '/shop', name: 'shop', component: Shop,
      meta: { requiresAuth: true }
    },
    {
      path: '/shopdetails',  name: 'shopdetails',  component: ShopDetails,
      meta: { requiresAuth: true }
    },
    {
      path: '/salecomplete',  name: 'salecomplete',  component: SaleComplete,
      meta: { requiresAuth: true }
    },
    {
      path: '/correct',  name: 'correct', component: CorrectResult,
      meta: { requiresAuth: true }
    },
    {
      path: '/fail', name: 'fail', component: FailResult,
      meta: { requiresAuth: true }
    },
    {
      path: '/score',  name: 'score',  component: Score,
      meta: { requiresAuth: true }
    },
    {
      path: '/login', name: 'login', component: Authentication
    },
    // {
    //   path: '/login', name: 'login', component: NovoSite
    // },
    {
      path: '/register', name: 'register', component: Register
    },
    {
      path: '/privacity',  name: 'privacidad',  component: Privacity
    },
    {
      path: '/successregister', name: 'successregister', component: SuccessRegister
    },

    {
      path: '/admin',   name: 'admintration', component: NovoAdmin,
      meta: { requiresAuth: true, breadcrumb : [
        { name: 'Administración'}
      ] },
      // children:[
      //   {
      //     path: 'quizzes', component: MainQuestion,
      //   }
      // ]
    },
    {
      path: '/quizzes',   name: 'quizzes',  component: MainQuestion,
      meta: { requiresAuth: true }
    },
    {
      path: '/quizzes/detail/:id',   name: 'detailquiz', component: Detail,
      meta: { requiresAuth: true }
    },
    {
      path: '/loadquizzes',   name: 'loadquizzes',  component: LoadQuestion,
      meta: { requiresAuth: true }
    },
    {
      path: '/settingsgame',   name: 'settingsGame',  component: SettingsGame,
      meta: { requiresAuth: true }
    },
    {
      // path: '*', redirect: '/'
      path: '/quizzes/upload',
      name: 'uploadquiz',
      component: UploadQuestion,
    },
    {
      path: '/dashboardproducts',
      name: 'dashboardproducts',
      component: MainProducts,
    },
    {
      path: '/dashboardproducts/detail/:id',
      name: 'detailproduct',
      component: DetailProduct,
    },
    {
      path: '/dashboardproducts/create',
      name: 'createproduct',
      component: CreateProduct,
    },
    {
      path: '/dashboardproducts/upload',
      name: 'uploadproduct',
      component: UploadProduct,
    },
    {
      path: '/dashboardtokens',
      name: 'dashboardtokens',
      component: MainTokens,
    },
    {
      path: '/dashboardtransactions',
      name: 'dashboardtransactions',
      component: MainTransactions,
    },
    {
      path: '/dashboardtransactions/detail/:id',
      name: 'detailtransactions',
      component: DetailTransactions,
    },
    {
      path: '/dashboardusers',
      name: 'dashboardusers',
      component: MainUsers,
    },
    {
      path: '/dashboardusers/detail/:id',
      name: 'detailusers',
      component: DetailUsers,
    },
    {
      path: '/dashboardusers/create',
      name: 'createusers',
      component: CreateUsers,
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (dataStore.state.auth.authenticated) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router;