<template>
  <login-layout :showHeadBand="showBand" :bglayout="bglogin">
    <template v-slot:mainContent>
      <div class="logo-insulin my-5">
        <img src="../assets/img/logoInsulin.svg" alt="" />
      </div>

      <section
        class="welcome-novo d-flex flex-column text-center px-md-3 px-lg-4"
      >
        <h1 class="my-3">Bienvenido a NovoClue</h1>
        <p class="my-3">
          el juego de preguntas que te regala grandes sorpresas.
        </p>
      </section>

      <form class="form-novo-login px-md-3 px-lg-4">
        <div class="mb-3">
          <label for="email" class="form-label form-label-novo">Email</label>
          <input
            id="email"
            type="email"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="email"
            placeholder="email"
          />
        </div>
        <div class="mb-2">
          <label for="password" class="form-label form-label-novo">Pass</label>
          <input
            id="password"
            type="password"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="password"
            placeholder="password"
          />
        </div>
        <div class="d-flex flex-column my-5 login_container">
          <button
            type="button"
            name="button"
            @click="handleAuth"
            :disabled="activeBtn"
            class="btn btn-primary-novo"
          >
            Iniciar
          </button>
          <p class="text-center pt-5 pb-2 txt-color-secondary">
            ¿Aún no te registras?
          </p>
          <router-link class="btn btn-secondary-novo" to="/register"
            >Házlo</router-link
          >
        </div>
      </form>
      <loading-novo></loading-novo>
    </template>
  </login-layout>
</template>

<script>
import LoginLayout from "./layouts/LoginLayout";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import LoadingNovo from "./shared/Loading";

export default {
  components: {
    LoginLayout,
    LoadingNovo,
  },
  data: () => {
    return {
      bglogin: "bglogin",
      email: "",
      password: "",
      showBand: false,
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticated,
      errorMessage: (state) => state.auth.errorContent,
      errorTitle: (state) => state.auth.errorTtl,
      isAdminUser: (state) => state.auth.isAdminUser,
    }),
    activeBtn() {
      return this.$v.$invalid;
    },
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...mapActions(["authenticate", "setLoadingState"]),
    async handleAuth() {
      if (!this.$v.$invalid) {
        this.setLoadingState(true);
        this.authenticate({
          email: this.email,
          password: this.password,
          remember_me: true,
        })
          .then(() => {
            this.setLoadingState(false);
            if (this.authenticated) {
              if(this.isAdminUser)
                this.$router.push("/admin");
              else
                this.$router.push("/start");
            } else {
              this.$iosAlert({
                title: this.errorTitle,
                text: this.errorMessage,
              });
            }
          })
          .catch((err) => {
            this.setLoadingState(false);
            console.error("Algo falló", err);
            this.$iosAlert({
              title: this.errorTitle,
              text: this.errorMessage,
            });
          });
      }
    },
  },
  created() {
    if (this.$route.query?.usuario == "activo") {
      this.showBand = true;
    }
  },
};
</script>