<template>
  <div class="left side-menu h-auto min-height-side">
    <div class="sidebar-inner slimscrollleft">
      <!--- Profile -->
      <div class="profile-info">
        <div class="col-xs-8">
          <div class="profile-text">
            <img src="../../assets/img/admin/logo-nordisk.svg" class="mt-4 navbar-admin-img"/> <br> Bienvenido <b>{{user.name}} {{user.firstName}}</b>
          </div>
        </div>
      </div>
      <!--- Divider -->
      <div class="clearfix"></div>
      <hr class="divider" />
      <div class="clearfix"></div>
      <!--- Divider -->
      <div id="sidebar-menu">
        <ul>
          <li class='has_sub' :class="{ 'active-menu' : isShowQuestion }" @click="isShowQuestion = !isShowQuestion">
            <a href="javascript:void(0)"> Preguntas </a>
          </li>
          <transition name="slide">
            <ul class="child" v-if="isShowQuestion">
              <li>
                <router-link to="/quizzes">Listado</router-link>
              </li>
              <li>
                <router-link to="/quizzes/upload">Carga masiva</router-link>
              </li>
            </ul>
          </transition>
          <li :class="{ 'active-menu' : isShowProducts }" @click="isShowProducts = !isShowProducts">
            <a href="javascript:void(0)"> Productos </a>
          </li>
          <transition name="slide">
            <ul class="child" v-if="isShowProducts">
              <li>
                <router-link to="/dashboardproducts">Listado</router-link>
              </li>
              <li>
                <router-link to="/dashboardtransactions">Transacciones</router-link>
              </li>
              <li>
                <router-link to="/dashboardtokens">Tokens</router-link>
              </li>
              <li>
                <router-link to="/dashboardproducts/upload">Carga masiva</router-link>
              </li>
            </ul>
          </transition>
          <li>
            <router-link to="/dashboardusers">Usuarios</router-link>
          </li>
          <!-- <li><a href="#">Reportes</a></li> -->
          <li>
            <router-link to="/settingsgame">Configuraciones</router-link>
          </li>
        </ul>
        <div class="clearfix"></div> 
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data: () => {
    return {
      isShowQuestion: false,
      isShowProducts: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.identifiedUser,
    }),
  },
  mounted(){ }
};
</script>
<style lang="scss" scoped>

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>