<template>
  <div class="topbar">
    <div class="topbar-left">
      <div class="logo">
        <h1 class="title-admin-novo">
          <router-link class="cursor" to="/admin">
            Novo
          </router-link>
        </h1>
      </div>
      <button class="button-menu-mobile open-left" v-on:click="onClickhamburgermenu()">
        <i style="color: white;" class="fa fa-bars"></i>
      </button>
    </div>

    <div class="navbar navbar-admin-right" role="navigation">
      <div class="d-flex justify-content-between w-100 px-3">
        <h3 class="navbar-text ml-2">
          Administrador Novo Game
        </h3>
        <div class="d-flex justify-content-around icon-admin-navbar">
          <router-link class="cursor" to="/start">
            <i style="font-size:30px;color: white;" class="fa fa-gamepad"></i>
          </router-link>
          <a class="pl-4 cursor" @click.prevent="handleLogout">
            <i style="font-size:30px;color: white;" class="fa fa-sign-out"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: "TopBarAdmin",
  methods: {
    ...mapActions(['logout']),
    handleLogout(){
      this.logout();
      this.$router.push('/');
    },
    onClickhamburgermenu() {
      this.$emit('hamburgermenu');
    }
  },
};
</script>
<style lang="scss">
@import "../../assets/css/colors";
.title-admin-novo{
  a{
    color: $color-text-white;
    text-decoration: none;
    &.hover{
      color:$color-card-2;
    }
  }
}
</style>