import {apiUrl} from '../env';
import Axios from 'axios';

export default {
  state: {
    quiz: {},
    categories: [],
    categorySlctd: {},
    options: ['A', 'B', 'C', 'D'],
    wallet:{},
    timeFinished: false,
    tryAgain: false,
    showAgain: false,
    showFoqui: true,
    errorMessage: "",
    errorTitle: "",
    topten: [],
    settingsGame:{
      initialMessage: '',
      timeForResponse:0,
      multiplyPoints:0
    },
    userData: {
      id: 0,
      name: "",
      firstName: "",
      email: "",
      avatar: "",
      phone: "",
      points: 0,
      crown: 0,
      questionsAnswered: 0,
    }
  },
  mutations: {
    // setInitialGame(state){
    //   state.timeForResponse = 15;
    //   state.showFoqui = true;
    // },
    setCategories(state, data) {
      state.categories = data;
    },
    setCategorySlctd(state, cat){
      state.categorySlctd = cat
    },
    setQuiz(state, quiz){
      state.quiz = quiz;
    },
    setErrorMsg(state, error){
      state.errorTitle = error.title;
      state.errorMessage = error.errormsg;
    },
    setUserWallet(state, wallet){
      state.wallet = wallet
    },
    setTimeFinished(state, finished){
      state.timeFinished = finished
    },
    setTryAgain(state, stts){
      state.tryAgain = stts
    },
    setShowAgain(state, stts){
      state.showAgain = stts
    },
    setTopTenUsers(state, topten){
      state.topten = topten
    },
    setShowAssistant(state, show){
      state.showFoqui = show
    },
    setGameSettings(state, settings){
      state.settingsGame.initialMessage = settings.initialMessage;
      state.settingsGame.timeForResponse = settings.timeforresponse;
      state.settingsGame.multiplyPoints = settings.multiplypoints;
    },
    setUserData(state, userData){
      state.userData.id = userData.id;
      state.userData.name = userData.name;
      state.userData.firstName = userData.firstName;
      state.userData.email = userData.email;
      state.userData.avatar = userData.avatar;
      state.userData.phone = userData.phone;
      state.userData.points = userData.points;
      state.userData.crown = userData.crown;
      state.userData.questionsAnswered = userData.questionsAnswered;
    }
  },
  getters:{ },
  actions:{
    async getQuiz(context, idUser){
      const {tryAgain} = this.state.game;
      const idCategory = this.state.game.categorySlctd.id;
      if(!tryAgain){
        context.commit('setQuiz', {});  
        try{
          let response = (
            await context.getters.authenticatedAxios.get(`${apiUrl}/quiz/${idCategory}/${idUser}`)
          );
          if(response?.data && !response.data.isError){
            //Succes response
            context.commit('setQuiz', response.data.data[0]);  
            context.commit('setErrorMsg', { title: "", errormsg: "" });
            return true;
          }else{
            // Algo salió mal 
            context.commit('setQuiz', {});
            context.commit('setErrorMsg', {title: 'Wow, ¡esto no lo esperabamos!', errormsg: `${response.data.error} Elige otra categoría, ¡pronto habrá mas preguntas aquí!.` });
            return false;
          }
        }catch(err){
          context.commit('setQuiz', {});
          context.commit('setErrorMsg', { title: 'Ups! Algo salió mal.', errormsg: '¡lo sentimos!. Te llevaremos sano y salvo al inicio. ;)' });
          console.log('Error: ', err);
        }
      }else{
        return true;
      }
    },
    async getCategories(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/category`)
      ).data;
      context.commit('setCategories', response.data);
    },
    selectedCategory(context, cat){
      context.commit('setCategorySlctd', cat);
    },
    async getWallet(context, id){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/wallet/${id}`)
      ).data;
      context.commit('setUserWallet', response[0]);
    },
    async updateCronws(context, wallet){
      let response = (
        await context.getters.authenticatedAxios.put(`${apiUrl}/wallet/${wallet.id}`, wallet)
      ).data;
      context.commit('setUserWallet', response);
    },
    updateTimeFinished(context, stts){
      context.commit('setTimeFinished', stts);
    },
    updateTryAgain(context, stts){
      context.commit('setTryAgain', stts);
    },
    updateShowAgain(context, stts){
      context.commit('setShowAgain', stts);
    },
    updateShowAssitant(context, stts){
      context.commit('setShowAssistant', stts);
    },
    // initialGame(context){
    //   context.commit('setInitialGame');
    // },
    async getTopUsers(context, limit){
      let response = (
        await Axios.get(`${apiUrl}/top/${limit}`)
      ).data;
      context.commit('setTopTenUsers', response);
    },
    async getConfigurationsGame(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/settings`)
      );
      if(!response.data.isError){
        context.commit('setGameSettings', response.data.data[0]);
      }else{
        context.commit('setGameSettings', {initialMessage:'', timeforresponse: 0, multiplypoints: 0});
      }
    },
    async getConfigsUser(context, idUser){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/userinfo/${idUser}`)
      );
      if(!response.data.isError){
        context.commit('setUserData', response.data.data);
      }else{
        context.commit('setUserData', 
        {
          id: 0, name: "", firstName: "", email: "", avatar: 0, phone: "", points: 0, crown: 0, questionsAnswered: 0,
        }
        );
      }
    },
    async updateConfigsUser(context, settings){
      let response = (
        await context.getters.authenticatedAxios.put(`${apiUrl}/userinfo/${settings.id}`, settings)
      );
      return !response.data.isError ? true : false;
    }
  }
}