// import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    transactions: [],
    transaction: {},
    success: false,
    metatransaction:{
      lastpage:0
    }
  },
  getters: { },
  mutations: {
    setTransactions(state, data) {
      state.transactions = data;
    },
    setMetaTransactions(state, data){
      state.metatransaction.lastpage = data
    },
    setDetailTransaction(state, data) {
      state.transaction = data;
    },
    setSuccess(state, data) {
      state.success = data;
    },
  },
  actions: {
    async getTransactions(context, page){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/alltransactions?page=${page}`)
      ).data;
      context.commit('setMetaTransactions', response.last_page);
      context.commit('setTransactions', response.data);
    },
    /*async updateTransactionDetail(context, inf){
      let response = (
        await context.getters.authenticatedAxios.post(`${apiUrl}/admin/tokendetail`, inf)
      );
      let res = response.status === 201 ? true : false;
      context.commit('setSuccess', res);
      context.dispatch("getTokens", 1);
      return res;
    },*/

  }
}