<template>
  
  <div 
    @click="handleCategory(id)"
    class="card-novo-category card card-novo my-5 text-center d-flex cursor">
    <h1>{{title}}</h1>
    <img v-if="image==='crown-0'" class="img-crowns my-4" src="../../assets/img/crown.svg" alt="" />
    <img v-if="image==='crown-1'" class="img-crowns my-4" src="../../assets/img/crowns-2.svg" alt="" />
    <img v-if="image==='crown-2'" class="img-crowns my-4" src="../../assets/img/crowns-3.svg" alt="" />
    <h5 class="txt-color-primary">{{description}}</h5>
    <a 
      href="#" 
      class="w-100 iwantthis mt-5"></a>
    <h5 class="txt-color-primary">Quiero este</h5>
  </div>
</template>
<script>

export default {
  props: {
    id : Number,
    title : String,
    image : String,
    description : String
  },
  computed: {
    pathImg() {
      return `../../assets/img/${this.image}`;
    } 
  },
  methods: {
    handleCategory(id){
      this.$emit('selectedCategory', id)
    }
  }
};
</script>