<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Transacciones actuales</h1>
        <h3>Listado</h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Transacciones</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Producto</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Total</th>
                <th scope="col">Fecha</th>
                <th scope="col">Estatus del Token</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="q in transactions" :key="q.id">
                <td>{{ q.id }}</td>
                <td>{{ q.shopping_carts.product.name }}</td>
                <td>{{ q.shopping_carts.quantity }}</td>
                <td>${{ q.total}}</td>
                <td>{{ q.datePurchase | moment }}</td>
                <td>{{ q.status }}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <paginate
          :page-count="pageCount"
          :click-handler="handleClickPage"
          :prev-text="'Anterior'"
          :next-text="'Siguiente'"
          :page-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :page-link-class="'page-link'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import Paginate from 'vuejs-paginate';
import moment from 'moment';

export default {
  components: {
    DashboardLayout,
    SideBar,  TopBarAdmin, Paginate
  },
  computed: {
    ...mapState({
      transactions: (state) => state.dashboardtransactions.transactions,
      pageCount: (state) => state.dashboardtransactions.metatransaction.lastpage,
    }),
  },
  methods: {
    ...mapActions(["getTransactions"]),
    handleClickPage(pageNum){
      this.getTransactions(pageNum);
    },
    moment: function () {
        return moment();
    }
  },
  mounted() {
    this.getTransactions(1);
  },
  filters: {
    moment: function (date) {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }
};
</script>