<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Preguntas actuales</h1>
        <h3>Listado</h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Preguntas</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Pregunta</th>
                <th scope="col">Respuesta Correcta</th>
                <th scope="col">Categoría</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="q in questions" :key="q.id">
                <td>{{ q.id }}</td>
                <td>{{ q.question }}</td>
                <td>{{ q.correct_ans }}</td>
                <td>{{ q.category.name }}</td>
                <td>
                  <a
                    @click.prevent="handleEdithQuestion(q.id)"
                    class="btn btn-primary mr-2"
                  >
                    <vue-fontawesome icon="edit"></vue-fontawesome>
                  </a>
                  <a
                    @click.prevent="handleDeleteQuestion(q.id)"
                    class="btn btn-danger"
                  >
                    <vue-fontawesome icon="trash"></vue-fontawesome>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <paginate
          :page-count="pageCount"
          :click-handler="handleClickPage"
          :prev-text="'Anterior'"
          :next-text="'Siguiente'"
          :page-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :page-link-class="'page-link'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import Paginate from 'vuejs-paginate';

export default {
  components: {
    DashboardLayout,
    SideBar,  TopBarAdmin, Paginate
  },
  computed: {
    ...mapState({
      questions: (state) => state.dash.questions,
      pageCount: (state) => state.dash.metaquestion.lastpage,
    }),
  },
  methods: {
    ...mapActions(["getQuestions"]),
    onDownload(payload) {
      alert(payload.msg);
    },
    handleEdithQuestion(idQuestion) {
      this.$router.push({
        name: "detailquiz",
        params: { id: idQuestion },
      });
    },
    handleDeleteQuestion(idQuestion) {
      console.log(idQuestion);
    },
    handleClickPage(pageNum){
      this.getQuestions(pageNum);
    }
  },
  async mounted() {
    console.log('Hello..');
    await this.getQuestions(1);
  },
};
</script>