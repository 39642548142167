<template>
  <div id="wrapper" v-bind:class="[class_menu_admin]">
    <slot name="TopBarContent"></slot>
    <slot name="SideBarContent"></slot>
    <menu-hamburgh @hamburgermenu="hamburgermenu2()"/>
    <div class="content-page">
      <div class="content">
        <slot name="TitleDashPage"></slot>
        <div class="row">
          <div class="col-lg-12">
            <div class="widget">
            <slot name="MainDashContent"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBarAdmin from "../Dashboard/TopBarAdmin.vue";

export default {
  components:{
    'menu-hamburgh': TopBarAdmin,
  },
  data: () => {
    return {
     class_menu_admin: '',
    };
  },
  methods: {
    hamburgermenu2() {
      if(this.class_menu_admin == ''){
        this.class_menu_admin = 'enlarged';
      }else{
        this.class_menu_admin = '';
      }
    },
  },
};
</script>