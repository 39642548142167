import {apiUrl} from '../env';

export default {
  state: {
    products: [],
    productdetail: {},
    product: {},
    order: {},
    errorMessage: "",
    errorTitle: "",
  },
  mutations: {
    setProducts(state, data) {
      state.products = data;
    },
    setProduct(state, product){
      state.product = product;
    },
    setProductDetails(state, data){
      state.productdetail = data;
    },
    setErrorMsg(state, error){
      state.errorTitle = error.title;
      state.errorMessage = error.errormsg;
    },
  },
  getters:{ },
  actions:{
    async getProducts(context){
      try{
        let response = (
          await context.getters.authenticatedAxios.get(`${apiUrl}/product`)
        );
        if(response?.data && !response.data.isError){
          context.commit('setProducts', response.data.data);
          return true;
        }else{
          context.commit('setProducts', {});
          context.commit('setErrorMsg', {title: 'Wow, ¡esto no lo esperabamos!', errormsg: `${response.data.error}` });
          return false;
        }
      }catch(err){
          context.commit('setProducts', {});
          context.commit('setErrorMsg', { title: 'Ups! Algo salió mal.', errormsg: '¡lo sentimos!. Te llevaremos sano y salvo al inicio. ;)' });
      }
    },
    selectedProduct(context, product){
      context.commit('setProduct', product);
    },
    async getProductDetails(context, productdetails){
      try{
        let response = (
          await context.getters.authenticatedAxios.get(`${apiUrl}/product/${productdetails}`)
        );
        if(response?.data && !response.data.isError){
          context.commit('setProductDetails', response.data.data[0]);
          return true;
        }else{
          context.commit('setProductDetails', {});
          context.commit('setErrorMsg', {title: 'Wow, ¡esto no lo esperabamos!', errormsg: `${response.data.error}` });
          return false;
        }
      }catch(err){
          context.commit('setProductDetails', {});
          context.commit('setErrorMsg', { title: 'Ups! Algo salió mal.', errormsg: '¡lo sentimos!. Te llevaremos sano y salvo al inicio. ;)' });
      } 
    },
    async saleProductAccept(context, saleproduct){
      try{
        let response = (
          await context.getters.authenticatedAxios.post(`${apiUrl}/shoppingcart`, saleproduct)
        );
        if(response?.data && !response.data.isError){
          return true;
        }else{
          context.commit('setErrorMsg', {title: 'Wow, ¡esto no lo esperabamos!', errormsg: `${response.data.error}` });
          return false;
        }
      }catch(err){
          context.commit('setErrorMsg', { title: 'Ups! Algo salió mal.', errormsg: '¡lo sentimos!. Te llevaremos sano y salvo al inicio. ;)' });
      }
    },

  }
}