<template>
  <div class="overlay" :class="{ active: show }" >
    <div class="content-overlay">
      <div class="content-alert">
        <p>{{contentTxt}}</p>
      </div>
      <img src="../../assets/img/foqui.svg" alt="">
      <section class="d-flex justify-content-center mt-5">
        <button v-if="canCancelActions"
          @click="handleCancel"
          class="btn mr-2 btn-secondary-novo">Cancelar</button>
        <button 
          @click="handleAccept"
          class="btn btn-primary-novo">Aceptar</button>
      </section>
      <!-- <a href="#">No volver a mostrar</a> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show : Boolean,
    contentTxt : String,
    canCancelActions: Boolean
  },
  methods:{
    handleAccept(){
      this.$emit('accept')
    },
    handleCancel(){
      this.$emit('cancel')
    }
  }
}
</script>
<style>
.active{
  display: block !important;
}
.overlay{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  z-index: 1021; /* Specify a stack order in case you're using a different order for other elements */
}
.content-overlay{
  position: absolute;
  top: 50%;
  left: 50%;
  /* font-size: 50px; */
  color: white;
  text-align: center;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
</style>