<template>
  <shop-layout :bglayout="bgRegister">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>

    <template v-slot:mainContent>
      <div class="background-salecomplete px-0">
        <div class="d-flex justify-content-around">
          <h1><i>¡Felicidades!</i></h1>
        </div>
        <div class="d-flex justify-content-center">
          <p class="text-salecomplete-description">
            Has adquirido el artículo <br />
            puntos al doble <br />
            para la siguiente ronda
          </p>
        </div>
        <br />
        <br />
        <br />
        <div class="d-flex justify-content-center">
          <router-link to="/start" class="btn btn-primary-novo txt-uppercase">REGRESAR AL JUEGO</router-link>
        </div>
        <!-- <br />
        <br />
        <br />
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary-novo btn-trophy"
          ></button>
        </div> -->
      </div>
    </template>

    <template v-slot:footerContent>
      <footer-shop></footer-shop>
    </template>
  </shop-layout>
</template>
<script>
import ShopLayout from "./layouts/ShopLayout";
import HeaderGame from "./shared/Header";
import FooterShop from "./shared/FooterAlt";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    ShopLayout,
    HeaderGame,
    FooterShop
  },
  computed: {
    ...mapState({ 
      user: (state) => state.auth.identifiedUser,
    })
  },
  methods: {
    ...mapActions(['getWallet']),
  },
  created() {
    this.getWallet(this.user.id);
  },
  data: () => {
    return {
      bgRegister: "bgRegister",
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors";

.background-salecomplete {
  background-color: $color-card-2;
  padding: 10px 10px 10px 10px;
}

.text-salecomplete-description {
  font-size: 2rem;
  color: $color-primary;
  text-align: center;
}
</style>