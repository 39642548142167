<template>
  <div v-if="loaded" class="row top-summary">
    <div class="col-lg-3 col-md-6">
      <div class="widget green-1 animated fadeInDown">
        <div class="widget-content padding">
          <div class="widget-icon">
            <i class="icon-globe-inv"></i>
          </div>
          <div class="text-box">
            <p class="maindata">TOTAL <b>USUARIOS</b></p>
            <h2>
              <span
                class="animate-number"
                data-value="25153"
                data-duration="3000"
                >{{ dataToCards.userCount }} </span
              >
            </h2>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="widget darkblue-2 animated fadeInDown">
        <div class="widget-content padding">
          <div class="widget-icon">
            <i class="icon-bag"></i>
          </div>
          <div class="text-box">
            <p class="maindata">TOTAL <b>PREGUNTAS</b></p>
            <h2>
              <span
                class="animate-number"
                data-value="6399"
                data-duration="3000"
                >{{ dataToCards.quizCount }}</span
              >
            </h2>

            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="widget pink-1 animated fadeInDown">
        <div class="widget-content padding">
          <div class="widget-icon">
            <i class="fa fa-dollar"></i>
          </div>
          <div class="text-box">
            <p class="maindata">TRANSACCIONES <b>HECHAS</b></p>
            <h2>
              <span
                class="animate-number"
                data-value="70389"
                data-duration="3000"
                >{{ dataToCards.purchaseCount }}</span
              >
            </h2>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="widget lightblue-1 animated fadeInDown">
        <div class="widget-content padding">
          <div class="widget-icon">
            <i class="fa fa-users"></i>
          </div>
          <div class="text-box">
            <p class="maindata">TOTAL de <b>PPRODUCTOS</b></p>
            <h2>
              <span
                class="animate-number"
                data-value="18648"
                data-duration="3000"
                >{{ dataToCards.productCount }}</span
              >
            </h2>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      dataToCards: (state) => state.dash.dataToCards,
    }),
    loaded() {
      return this.dataToCards ? true : false;
    },
  },
  methods: {
    ...mapActions(["getDataInCards"]),
  },
  async mounted() {
    await this.getDataInCards();
  },
};
</script>