<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Usuarios actuales</h1>
        <h3>Listado</h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Usuarios</h2>
        <div class="additional-btn">
          <a @click.prevent="createUser()" class="btn btn-success mr-2"><vue-fontawesome icon="plus"></vue-fontawesome></a>
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Iniciales</th>
                <th scope="col">Email</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Edad</th>
                <th scope="col">Género</th>
                <th scope="col">Tipo de Usuario</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="q in users" :key="q.id">
                <td>{{ q.id }}</td>
                <td>{{ q.name }} {{ q.firstName }} {{ q.secondName }}</td>
                <td>{{ q.initials }}</td>
                <td>{{ q.email }}</td>
                <td>{{ q.phone }}</td>
                <td>{{ q.age }}</td>
                <td v-if="q.gender == 0">M</td>
                <td v-else>H</td>
                <td v-if="q.isAdmin == 0">Jugador</td>
                <td v-else>Adminisitrador</td>
                <td>
                <a
                @click.prevent="handleEdithUser(q.id)"
                class="btn btn-primary mr-2"
                >
                    <vue-fontawesome icon="edit"></vue-fontawesome>
                </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <paginate
          :page-count="pageCount"
          :click-handler="handleClickPage"
          :prev-text="'Anterior'"
          :next-text="'Siguiente'"
          :page-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :page-link-class="'page-link'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import Paginate from 'vuejs-paginate';

export default {
  components: {
    DashboardLayout,
    SideBar,  TopBarAdmin, Paginate
  },
  computed: {
    ...mapState({
      users: (state) => state.dashboardusers.users,
      pageCount: (state) => state.dashboardusers.metauser.lastpage,
    }),
  },
  methods: {
    ...mapActions(["getUsers","deletedUser"]),
    createUser(){
      this.$router.push({
        name: "createusers",
      });
    },
    handleEdithUser(idUser) {
      this.$router.push({
        name: "detailusers",
        params: { id: idUser },
      });
    },
    handleClickPage(pageNum){
      this.getUsers(pageNum);
    },
  },
  mounted() {
    this.getUsers(1);
  }
};
</script>