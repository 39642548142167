<template>
  <shop-layout :bglayout="bgRegister">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>

    <template v-slot:mainContent>
      <div class="background-salecomplete">
        <div class="d-flex justify-content-around">
          <h1>Tienda</h1>
        </div>
        <div class="d-flex justify-content-center">
          <p class="text-salecomplete-description">{{ wallet.crown }} coronas </p>
          <p class="text-salecomplete-description2">para comprar</p>
        </div>
        <br />
        <div class="d-flex justify-content-center main-details">
          <div class="card text-center card-custom-details">
            <div class="card-body">
              <img
                class="img-fluid"
                src="https://teamnovonordisk.sportyfied.com/thumbs/regular/tnn19_03313dv_front_3d_700x700.png"
              />
            </div>
            <div class="text-muted background-card-footer">
              <p class="text-card-footer">{{ productdet.price }}</p>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-around" v-if="isShowsale">
          <div class="justify-content-center">
            <p class="text-salecomplete-description2">Espera un momento...</p>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="justify-content-start" v-if="wallet.crown > productdet.price">
            <button type="button" class="btn btn-primary-novo" v-if="isHiddensale" @click="saleSelectedtProduct">COMPRAR</button>
          </div>
          <div class="justify-content-end">
            <router-link to="/shop" class="btn btn-primary-novo txt-uppercase" v-if="isHiddensale">
              Volver
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footerContent>
      <footer-shop></footer-shop>
    </template>
  </shop-layout>
</template>
<script>
import ShopLayout from "./layouts/ShopLayout";
import HeaderGame from "./shared/Header";
import FooterShop from "./shared/FooterShop";
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    ShopLayout,
    HeaderGame,
    FooterShop
  },
  computed:{
    ...mapState({ 
      productd: (state) => state.shop.product,
      productdet: (state) => state.shop.productdetail,
      wallet: (state) => state.game.wallet,
      errorMessage: (state) => state.shop.errorMessage,
      errorTitle: (state) => state.shop.errorTitle,
    })
  },
  methods: {
    ...mapActions(['getProductDetails', 'saleProductAccept']),

    async saleSelectedtProduct(){
      if(this.wallet.crown < this.productdet.price){
        this.$iosAlert({
          title: "Lo sentimos",
          text: "No cuentas con suficientes coronas para completar tu compra",
        });
      }else{
        this.isHiddensale = false;
        this.isShowsale = true;
        
        const createdsale = await this.saleProductAccept({
          user_id: this.wallet.user_id,
          product_id: this.productdet.id,
          quantity:"1",
          amount: this.productdet.price,
          status:"Pagado"
        });
        if (!createdsale) {
          this.$iosAlert({
            title: this.errorTitle,
            text: this.errorMessage,
          }).then(() => this.$router.push('/shop') );
        }else{
          this.$router.push("/salecomplete");
        }
      }
    }

  },
  async created() {
    if (this.productd > 0){
      const detailproduct = await this.getProductDetails(this.productd);
      if(!detailproduct){
        this.$iosAlert({
          title: this.errorTitle,
          text: this.errorMessage,
        })
        .then(() => this.$router.push('/shop') );
      }
    }else{
      this.$router.push("/shop");
    }
  },
  data: () => {
    return {
      bgRegister: "bgRegister",
      isHiddensale: true,
      isShowsale: false,
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors";

.background-salecomplete {
  // background-color: $color-card-2;
  padding: 10px 10px 10px 10px;
}

.text-salecomplete-description {
  font-family: ApisBlack;
  font-size: 1.5rem;
  color: $color-primary;
  text-align: center;
  font-style: italic;
}

.text-salecomplete-description2 {
  font-size: 1.5rem;
  color: $color-primary;
  text-align: center;
  font-style: italic;
  padding-left: 0.5rem;
}

.text-card-footer {
  color: #ffffff !important;
  font-size: 2.5rem;
  font-family: ApisBlack;
}

.background-card-footer {
  background-color: $color-primary;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.card-custom-details {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}
.text-card-body {
  color:#003878!important;
  font-size: 1.5rem;
}

.background-card-body{
  background-color: $color-background;
}

.card-custom-details{
  border-top-left-radius: 2rem!important;
  border-top-right-radius: 2rem!important;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.main-details {
  padding: 10px;
}
</style>