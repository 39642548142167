import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    authenticated: localStorage.getItem('token') ? true : false,
    jwt: localStorage.getItem('token') || '',
    identifiedUser : {},
    errorContent: "",
    errorTtl: "",
    isAdminUser: false,
  },
  getters: {
    authenticatedAxios(state){
      return Axios.create({
        headers: {
          'Authorization': `Bearer ${state.jwt}`
        }
      });
    }
  },
  mutations: {
    setAuthenticated(state, data){
      state.jwt = data.access_token;
      state.authenticated = data.access_token ? true : false;
      state.identifiedUser = data.user;
      state.isAdminUser = data.user.isAdmin == 0 ? false : true;
    },
    setErrorData(state, error){
      state.errorTtl = error.title;
      state.errorContent = error.content;
    },
    clearAuthentication(state){
      state.authenticated = false;
      state.jwt = null;
      state.identifiedUser = {};
    },
  },
  actions: {
    async authenticate(context, credentials){
      return Axios.post(`${apiUrl}/login`, credentials).then(d => {
        console.log(d);
        if(d?.data && !d.data.isError){
          localStorage.setItem('token', d.data.access_token);
          context.commit('setAuthenticated', d.data.data);
          context.commit('setErrorData', {title: '', content: '' });
        }else{
          // Algo salió mal 
          context.commit('setErrorData', {title: 'Lo sentimos.', content: d.data.error });
          const data = {
            access_token: "",
            user: { 
              isAdmin:0
            }
          }
          context.commit('setAuthenticated', data);
        }
      }).catch(err => {
        context.commit('setErrorData', {title: 'Algo salío mal', content: err.response });
        const data = {
          access_token: "",
          user: { 
            isAdmin:0
          }
        }
        context.commit('setAuthenticated', data);
      });
    },
    async addNewUser(context, user) {
      let response = await Axios.post(`${apiUrl}/signup`, user, { 
        headers: {
          'Content-Type': 'application/json', 
          'Accept': 'application/json', 
          'X-Requested-With': 'XMLHttpRequest', 
        }
      });
      console.log(response);
      return response.status === 201 ? true : false;
    },
    logout(context){
      context.commit('clearAuthentication');
    }
  }
}