<template>
  <header class="d-flex justify-content-around align-items-center py-4 mb-2 header-novo header-relative" v-if="loaded && loadedUsrData">
    <div class="d-flex justify-content-start align-items-center" >
      <a  @click.prevent="toggle()" href="#" >
         <img class="avatar-head img-thumbnail" :src="require(`@/assets/avatars/${userData.avatar}`)" alt="">
        <!-- <img src="../../assets/img/avatar.svg" alt=""> -->
      </a>
      <div v-if="active" class="content-submenu">
        <router-link to="/profile">Ver perfil</router-link>
        <router-link to="/start">Inicio</router-link>
        <a @click.prevent="handleLogout" href="#">Salir</a>
      </div>
      
      <h3 class="pl-4">{{ user.name }}</h3>
    </div>
    <div class="d-flex justify-content-end">
      <div class="d-flex flex-column text-center mr-3" >
        <p class="py-0 mb-1 text-uppercase">Tienda</p>
        <span class="badge badge-novo py-0 cursor"  @click.prevent="handleToShop"> 
          <img class="carshop-head" src="../../assets/img/carshop.svg" alt="">
        </span>
      </div>
      <div class="d-flex flex-column text-center">
        <p class="py-0 mb-1 text-uppercase">Coronas</p>
        <span class="badge badge-novo pad-in">{{wallet.crown}}</span>
      </div>
    </div>
    
  </header>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  data () {
    return {
      active: false
    }
  },
  computed:{
    ...mapState({ 
        user: (state) => state.auth.identifiedUser,
        wallet: (state) => state.game.wallet,
        userData: (state) => state.game.userData,
    }),
    loadedUsrData() {
      return this.userData ? true : false;
    },
    loaded() {
      return this.wallet ? true : false
    }
  },
  methods: {
    ...mapActions(['logout', 'getConfigsUser']),
    toggle () {
      this.active = !this.active
    },
    handleLogout(){
      this.logout();
      this.$router.push('/');
    },
    handleToShop(){
      this.$router.push('/shop');
    }
  },
  async mounted() {
    await this.getConfigsUser(this.user.id);
  }
}
</script>
<style lang="scss">

</style>