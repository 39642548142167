<template>
  <div>
    <div class="container-fluid  bg-white" :class="bglayout">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="headerContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="mainContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-card-0">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="footerContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <!-- Header component -->
  </div>
</template>
<script>
export default {
  props: {
    bglayout: String,
  },
};
</script>