<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Editar Usuario</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Usuario</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="username" class="form-label form-label-novo">Nombre <span class="obligatory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="255"
                  v-model="dataToEdit.name"
                  placeholder="Nombre del Usuario"
                />           
              </div>
              <div class="form-group col-md-4">
                <label for="firstName" class="form-label form-label-novo">Apellidos <span class="obligatory">*</span></label>
                <input
                id="firstName"
                type="text"
                name=""
                class="form-control"
                maxlength="255"
                v-model="dataToEdit.firstName"
                placeholder="Apellidos"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="initials" class="form-label form-label-novo">Iniciales <span class="obligatory">*</span></label>
                <input
                id="initials"
                type="text"
                name=""
                class="form-control"
                value=""
                v-model="dataToEdit.initials"
                placeholder="Iniciales"
                maxlength="3"
                />
              </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                <label for="email" class="form-label form-label-novo">Correo electrónico <span class="obligatory">*</span></label>
                <input
                    id="email"
                    type="email"
                    name=""
                    class="form-control"
                    value=""
                    v-model="dataToEdit.email"
                    placeholder="Correo electrónico"
                    readonly
                />
                </div>
                <div class="form-group col-md-6">
                <label for="pass" class="form-label form-label-novo">Contraseña </label>
                <input
                id="pass"
                type="password"
                name=""
                class="form-control"
                value=""
                v-model="dataToEdit.password"
                placeholder="Contraseña"
                />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                <label for="phone" class="form-label form-label-novo">Teléfono</label>
                <input
                    id="phone"
                    type="text"
                    name=""
                    class="form-control"
                    value=""
                    v-model="dataToEdit.phone"
                    placeholder="Teléfono"
                />
                </div>
                <div class="form-group col-md-4">
                <label for="age" class="form-label form-label-novo">Edad</label>
                <input
                    id="age"
                    type="number"
                    name=""
                    class="form-control"
                    value=""
                    v-model="dataToEdit.age"
                    placeholder="Edad"
                />
                </div>
                <div class="form-group col-md-4">
                <label for="gender" class="form-label form-label-novo">Género</label>
                <select class="form-control" v-model="dataToEdit.gender">
                  <option :selected="dataToEdit.gender == null" value="">Seleccione un elemento</option>
                  <option :selected="dataToEdit.gender == '0'" value="0">M</option>
                  <option :selected="dataToEdit.gender == '1'" value="1">H</option>
                </select>
                </div>
            </div>
            <div class="form-row" align="center">
                <div class="form-group col-md-12">
                <label for="isAdmin" class="form-label form-label-novo">Tipo de usuario</label>
                <br>
                <input type="radio" id="isAdmin1" value="0" v-model="dataToEdit.isAdmin" :checked="dataToEdit.isAdmin == 0">
                <label for="isAdmin1">Jugador</label>
                <br>
                <input type="radio" id="isAdmin2" value="1" v-model="dataToEdit.isAdmin" :checked="dataToEdit.isAdmin == 1">
                <label for="isAdmin2">Adminisitrador</label>
                </div>
            </div>
            <button 
              @click="handleSaveChanges()"
              type="button" class="btn btn-primary">Guardar cambios</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  computed: {
    ...mapState({
      detail: (state) => state.dashboardusers.user,
      success: (state) => state.dashboardusers.success,
    }),
  },
  data() {
    return {
      dataToEdit: {}
    };
  },
  validations: {
    dataToEdit : {
      name: { required },
      firstName: { required },
      initials: { required },
      email: { required },
    }
  },
  methods: {
    ...mapActions(['getDetailUser', 'updateUserDetail']),
    async handleSaveChanges(){
      if (!this.$v.$invalid) {
        const res = await this.updateUserDetail(this.dataToEdit);
        if(res){
          this.$alert("Usuario Actualizado.");
        } else {
            console.error("Algo falló");
        }
      }else{
        this.$alert("Debes llenar los campos marcados con *.");
      }
    }
  },
  async created(){
    await this.getDetailUser(this.$route.params.id);
    this.dataToEdit = Object.assign({}, this.detail);
  }
};
</script>