<template>
  <div>
    <div class="sticky-top">
      <div class="container-fluid  bg-white" >
        <div class="container-lg">
          <div class="row">
            <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <slot name="headerContent"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid" :class="[bgColorLevel, bgtext]">
        <div class="container-lg">
          <div class="row">
            <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <slot name="titleContent"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid hvh" >
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="mainContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <!-- <slot name="footerContent"></slot> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Header component -->
  </div>
</template>
<script>
export default {
  props: {
    bgColorLevel: String
  },
  computed:{
    bgtext(){
      if(this.bgColorLevel === 'facil'){
        return 'bg-text-facil';
      }else if(this.bgColorLevel === 'intermedio'){
        return 'bg-text-intermedio';
      }else if(this.bgColorLevel === 'avanzado'){
        return 'bg-text-avanzado';
      }
      return "";
    }
  }
}
</script>