<template>
  <section class="question-section text-center mt-5" v-if="loaded">
    <h3 class="txt-question txt-color-primary">
      {{quiz.question}}
    </h3>
    <ul>
      <li v-for='(a, index) in quiz.answers' :key="a.id">
        <a href="#" 
          :class="bgColorLink"
          @click.prevent="slctdOption(a, options[index], $event)"
          class="d-block content-item">
          <span class="index-option badge badge-novo-item" :class="bgColorLevel">{{options[index]}}</span>
          <p class="item-text">{{a.content}}</p>
        </a>
      </li>
    </ul>
    <loading-novo></loading-novo>
  </section>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LoadingNovo from '../shared/Loading';

export default {
  components: {
    LoadingNovo
  },
  props: {
    bgColorLevel: String,
    bgColorLink: String,
  },
  computed:{
    ...mapState({ 
      selectedCat: (state) => state.game.categorySlctd,
      quiz: (state) => state.game.quiz,
      options: (state) => state.game.options,
      wallet: (state) => state.game.wallet,
      tryAgain: (state) => state.game.tryAgain,
      user: (state) => state.auth.identifiedUser,
      errorMessage: (state) => state.game.errorMessage,
      errorTitle: (state) => state.game.errorTitle,
      settings: (state) => state.game.settingsGame,
    }),
    loaded() {
      return this.quiz ? true : false
    },
  },
  methods:{
    ...mapActions(['getQuiz', 'setLoadingState', 'updateCronws', 'updateTimeFinished']),
    async slctdOption(opt){
      this.setLoadingState(true);
      // Emiter
      // this.$emit('selectedAnswer');
      if(opt.index === this.quiz.correct_ans){
        let newPoints = (this.wallet.points + this.selectedCat.points) * this.settings.multiplyPoints;
        let newCrowns =  (this.wallet.crown + this.selectedCat.points) * this.settings.multiplyPoints;
        await this.updateCronws({
           id: this.wallet.id,
           points: newPoints,
           crown: newCrowns
         });
        this.setLoadingState(false);
        this.$router.push('/correct');
      }else{
        this.setLoadingState(false);
        this.$router.push('/fail');
      }
    }
  },
  async created() {
    this.updateTimeFinished(false);
    const result = await this.getQuiz(this.user.id);
    if(result){
      this.$emit('handleTimerInit');
    }else{
      console.log(result);
      this.$iosAlert({
        title: this.errorTitle,
        text: this.errorMessage,
      })
      .then(() => this.$router.push('/start') );
    }
  },
};
</script>