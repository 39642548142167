// import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    users: [],
    user: {},
    success: false,
    metauser:{
      lastpage:0
    }
  },
  getters: { },
  mutations: {
    setUsers(state, data) {
      state.users = data;
    },
    setMetaUsers(state, data){
      state.metauser.lastpage = data
    },
    setDetailUser(state, data) {
      state.user = data;
    },
    setSuccess(state, data) {
      state.success = data;
    },
  },
  actions: {
    async addUser(context, user) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/user`, user, { 
        headers: {
          'Content-Type': 'application/json', 
          'Accept': 'application/json', 
          'X-Requested-With': 'XMLHttpRequest', 
        }
      });
      return response.status === 201 ? true : false;
    },
    async getUsers(context, page){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/allusers?page=${page}`)
      ).data;
      context.commit('setMetaUsers', response.last_page);
      context.commit('setUsers', response.data);
    },
    async getDetailUser(context, id = 0){
        let response = (
          await context.getters.authenticatedAxios.get(`${apiUrl}/admin/userdetail/${id}`)
        ).data;
        context.commit('setDetailUser', response[0]);
    },
    async updateUserDetail(context, user){
        let response = (
            await context.getters.authenticatedAxios.put(`${apiUrl}/admin/userdetail`, user)
        );
        let res = response.status === 201 ? true : false;
        context.commit('setSuccess', res);
        return res;
    },

  }
}