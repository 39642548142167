<template>
  <footer class="d-flex justify-content-end py-4" :class="bgimage">
    <div align="center" class="text-faileasy-description">
      TOP
      <a @click.prevent="handleGoToTop" class="d-flex align-items-end" href="#">
      <img class="img-fluid " src="../../assets/img/btncup.svg" alt="">
      </a>
    </div>
    
  </footer>
</template>
<script>
export default {
  props: {
    bgimage: String
  },
  methods:{
    handleGoToTop(){
      this.$router.push('/score');
    }
  }
}
</script>
<style lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "node_modules/bootstrap/scss/grid";

// .heighvh{
//   height: 15vh;
// }
@include media-breakpoint-between(xs, sm){
// @include media-breakpoint-up(xs) { 
  // .heighvh{
  //   height: 32vh;
  // }
  .bg-image-quiz{
    background-position: bottom;
  }
}
</style>