import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
// import './assets/css/owl.carousel.css';
import './assets/css/syles.scss';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueSimpleAlert from "vue-simple-alert";
import store from './store';
import router from './router';
import Vuelidate from 'vuelidate';
import iosAlertView from 'vue-ios-alertview';

import "font-awesome/css/font-awesome.min.css";
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);
import VueSmoothScroll from 'vue2-smooth-scroll';

Vue.use(VueSmoothScroll);
Vue.use(iosAlertView);
Vue.use(Vuelidate);
Vue.use(VueSimpleAlert);

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app');
