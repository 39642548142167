<template>
  <result-layout :textLayoutResult="textBackground" :bglayout="cssclasback">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>

    <template v-slot:mainContent>
      <div class="px-0 pt-5">
        <div class="d-flex justify-content-center">
          <img class="img-fluid my-4" v-if="imgrs == 'despertador'" src="../assets/img/despertador.svg" alt="" />
          <img class="img-fluid my-4" v-else src="../assets/img/brokenheart.svg" alt="" />
        </div>
        <div class="d-flex justify-content-center">
          <h1 class="text-center px-5">{{titleFail}}</h1>
        </div>
        <div class="d-flex justify-content-center">
          <p class="text-faileasy-description my-4"> {{msg}} </p>
        </div>
        <div class="d-flex flex-column px-novo">
          <button v-if="!tryAgain" type="button" @click="handleNewTry"
            class="btn btn-primary-novo text-uppercase my-3">
            Volver a intentar
          </button>
          <button type="button" @click="handleNextQuestion"
            class="btn btn-primary-novo text-uppercase my-3">Siguiente pregunta</button>
          <button type="button" @click="handleNewLevel"
            class="btn btn-secondary-novo text-uppercase my-3">Otro nivel</button>
        </div>
        <clipper-novo 
          :canCancelActions="true"
          :contentTxt="txtContent"
          :show="showOverlay"
          @accept="acceptAction" 
          @cancel="cancelAction" 
          >
        </clipper-novo>
      </div>
    </template>
   
    <template v-slot:footerContent>
      <footer-shop></footer-shop>
    </template>
  </result-layout>
</template>
<script>
import ResultLayout from "./layouts/ResultLayout";
import HeaderGame from "./shared/Header";
import FooterShop from "./shared/FooterAlt";
import ClipperNovo from './GameComponents/Clipper';
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ResultLayout, HeaderGame, FooterShop, ClipperNovo
  },
  data: () => {
    return {
      bgRegister: "bg-fail",
      msg: "",
      showOverlay:false,
      txtContent:"",
      titleFail: ""
    };
  },
  computed: {
    ...mapState({ 
      timeout : (state) => state.game.timeFinished,
      wallet: (state) => state.game.wallet,
      tryAgain : (state) => state.game.tryAgain,
      selectedCat: (state) => state.game.categorySlctd,
    }),
    cssclasback(){
      return 'bg-fail';
    },
    textBackground(){
      return this.timeout ? '' : 'bg-txt-fail';
    }
  },
  methods: {
    ...mapActions(['updateTryAgain', 'updateCronws', 'setLoadingState']),
    showMsg(){
      this.msg = this.timeout ? '¡Puedes intentarlo nuevamente!' : 'Inténtalo de nuevo, ¡tienes otra oportunidad!.';
      this.imgrs = this.timeout ? 'despertador' : 'brokenheart';
      this.titleFail = this.timeout ? '¡Tu tiempo se acabó!' : '¡Incorrecto!';
    },
    handleNewTry(){
      this.showOverlay = true;
      this.txtContent = `Si repites la pregunta y fallas, perderás ${(this.selectedCat.points)*2} coronas`;
    },
    acceptAction(){
      this.showOverlay = false;
      this.txtContent = ``;
      this.updateTryAgain(true);
      this.$router.push('/quiz');
    },
    cancelAction(){
      this.showOverlay = false;
      this.txtContent = ``;
    },
    handleNextQuestion(){
      this.updateTryAgain(false);
      this.$router.push('/quiz');
    },
    handleNewLevel(){
      this.updateTryAgain(false);
      // this.updateShowAssitant(true);
      this.$router.push('/start');
    },
    async handleRestPoints(){
      this.setLoadingState(true);
      const restPoints = (this.selectedCat.points)*2;
      let newPoints = this.wallet.points - restPoints;
      let newCrowns = this.wallet.crown - restPoints;
      newPoints = this.is_negative_number(newPoints) ? 0 : newPoints;
      newCrowns = this.is_negative_number(newCrowns) ? 0 : newCrowns;
      await this.updateCronws({
          id: this.wallet.id,
          points: newPoints,
          crown: newCrowns
        });
      this.setLoadingState(false);
    },
    is_negative_number(number=0){
      if(number < 0 ){
        return true;
      }else{
        return false;
      }
    },
  },
  created(){
    if(this.tryAgain){
      this.handleRestPoints();
    }
    this.showMsg();
  }
};
</script>
<style lang="scss">
@import "../assets/css/colors";

.text-faileasy-description {
  font-size: 2rem;
  color: $color-primary;
  text-align: center;
}
</style>