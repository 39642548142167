<template>
  <shop-layout :bglayout="bgRegister">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>
    
    <template v-slot:mainContent>
        <div class="background-salecomplete">
            <div class="d-flex justify-content-around">
                <h1>Tienda</h1>
            </div>
            <div class="d-flex justify-content-center">
                <p class="text-salecomplete-description">{{ wallet.crown }} coronas </p> <p class="text-salecomplete-description2"> para comprar</p>
            </div>
            <br>
            <div class="justify-content-center">
                  <div class="container">
                      <div class="row">
                        
                      <div class="col-4 cards" v-for="inf in products" v-bind:key="inf.id">
                          <div class="col-12" @click.prevent="handleSelectedtProduct(inf.id)">
                            <div class="card text-center card-custom">
                              <div class="text-muted height-min-max background-card-header">
                                <p class="text-card-header">{{ inf.name }}</p>
                              </div>
                              <div class="card-body" >
                                <img class="img-fluid" src="https://teamnovonordisk.sportyfied.com/thumbs/regular/tnn19_03313dv_front_3d_700x700.png">
                              </div>
                              <div class="text-muted background-card-footer">
                                <p class="text-card-footer">{{ inf.price }}</p>
                              </div>
                            </div>
                          </div>
                      </div>
                      
                    </div>
                  </div>
            </div>
            <br>
            <div class="d-flex justify-content-center">
               <router-link to="/start" class="btn btn-primary-novo txt-uppercase">REGRESAR AL JUEGO</router-link>
            </div>
        </div>
    </template>
    
    <template v-slot:footerContent>
      <footer-shop></footer-shop>
    </template>
  </shop-layout>
</template>
<script>
import ShopLayout from "./layouts/ShopLayout";
import HeaderGame from "./shared/Header";
import FooterShop from "./shared/FooterShop";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    ShopLayout,
    HeaderGame,
    FooterShop
  },

  computed: {
    ...mapState({ 
      products: (state) => state.shop.products,
      selectedPro: (state) => state.shop.product,
      user: (state) => state.auth.identifiedUser,
      wallet: (state) => state.game.wallet,
      errorMessage: (state) => state.shop.errorMessage,
      errorTitle: (state) => state.shop.errorTitle,
    })
  },
  methods: {
    ...mapActions(['getProducts', 'selectedProduct', 'getWallet']),
    handleSelectedtProduct(productId){
      this.selectedProduct(productId);
      if(this.selectedPro > 0){
        this.$router.push('/shopdetails');
      }
    }
  },
  async created() {
    const allproducts = await this.getProducts();
    if(!allproducts){
      this.$iosAlert({
        title: this.errorTitle,
        text: this.errorMessage,
      })
      .then(() => this.$router.push('/start') );
    }
    this.getWallet(this.user.id);
  },

  data: () => {
    return {
      bgRegister: "bgRegister",
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors";

.background-salecomplete {
  // background-color: $color-card-2;
  padding: 10px 10px 10px 10px;
}

.text-salecomplete-description {
  font-family: ApisBlack;
  font-size: 1.5rem;
  color: $color-primary;
  text-align: center;
  font-style: italic;
}

.text-salecomplete-description2 {
  font-size: 1.5rem;
  color: $color-primary;
  text-align: center;
  font-style: italic;
  padding-left: 0.5rem;
}

.text-card-footer {
  color: #ffffff!important;
  font-size: 1.5rem;
  font-family: ApisBlack;
}

.background-card-footer{
  background-color: $color-primary;
  
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.text-card-header {
  color: $color-primary!important;
  font-size: 1.5rem;
  font-family: ApisBlack;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.background-card-header{
  background-color: $color-background;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.card-custom{
  border-top-left-radius: 1rem!important;
  border-top-right-radius: 1rem!important;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -33.33%;
  margin-right: -33.33%;
}

@media (min-width: 576px) {
  .card-custom{
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (min-width: 768px) {
  .card-custom{
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (min-width: 992px) {
  .card-custom{
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 0%;
    margin-right: 0%;
  }
}
@media (min-width: 1200px) {
  .card-custom{
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.cards{
  padding:9px;
}

</style>