<template>
<!--https://www.npmjs.com/package/vue-loading-overlay -->
  <div class="vld-parent">
    <loading :active.sync="isLoading" 
    :loader="'dots'"
    :can-cancel="false" 
    :color="'#003878'"
    :is-full-page="true"></loading>
  </div>
</template>
<script>

import { mapState } from "vuex";
import Loading from 'vue-loading-overlay';
export default {
  components: { Loading },
  computed: {
    ...mapState({
      isLoading : (state) => state.isLoading,
    }),
  }
};
</script>