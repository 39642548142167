<template>
  <div>
    <div class="container-fluid sticky-top bg-white">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="headerContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" :class="[bglayout, textLayoutResult]">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="mainContent"></slot>
          </div>
        </div>
      </div>
    
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="footerContent"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bglayout: String,
    textLayoutResult: String,
  },
}
</script>
<style lang="scss">
@import "../../assets/css/colors";

.bg-fail{
  background: $color-light;
}
.bg-win{
  background: $color-card-1;
}
</style>