<template>
  <register-layout :bglayout="bgRegister">
    <template v-slot:titleContent>
      <h3 class="txt-color-white h8-vh d-flex align-items-center px-2">
        Aviso de Privacidad
      </h3>
    </template>
    <template v-slot:mainContent>
      <section class="privacity-content">
        <p>
          La privacidad es muy importante para Novo Nordisk México, S.A. de C.V.
          siempre nos esforzamos en proteger la privacidad de los datos
          personales que recibimos de nuestros clientes, pacientes,
          colaboradores y otros, y los utilizamos de una forma ética y acorde
          con las leyes de privacidad de datos, las regulaciones locales e
          internacionales y a nuestra política de privacidad de datos.
        </p>
        <p>
          Para Novo Nordisk México, S.A. de C.V., ubicada en Homero 1500, piso
          3, Col. Polanco Chapultepec, Delegación Miguel Hidalgo en la Ciudad de
          México, C.P. 11560, (en adelante “Novo Nordisk”) el tratamiento
          legítimo, controlado e informado de sus datos personales es de vital
          importancia para alcanzar los objetivos corporativos a través de todas
          las áreas del negocio y reiterar nuestro compromiso con la privacidad
          y su derecho a la autodeterminación informativa.
        </p>
        <p>
          El presente Aviso de Privacidad tiene como objeto informarle sobre el
          tratamiento que se le dará a sus datos personales cuando los mismos
          son solicitados, recabados, utilizados, almacenados y/o transferidos
          por Novo Nordisk, lo cual se lleva a cabo de conformidad con las
          disposiciones de la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (en adelante la “Ley”).
        </p>
        <h4>Datos Personales o información personal</h4>
        <p>
          Algunos de los datos personales y/o datos personales sensibles que nos
          proporcione, por ejemplo: al registrarse voluntariamente en algunos de
          nuestros programas y/o eventos, en nuestra bolsa de trabajo, enviarnos
          un correo electrónico con dudas o comentarios y aquellos que se
          recaben necesariamente como consecuencia del reporte de un evento
          adverso o procedimientos de fármaco vigilancia, pueden ser de manera
          enunciativa, más no limitativa los siguientes:
        </p>
        <ul>
          <li>
            <p>
              Datos de identificación como: nombre, domicilio, teléfono, correo
              electrónico, CURP, fecha de nacimiento, edad, nacionalidad,
              imagen, fotografía, país de residencia, número de seguridad
              social, forma de contacto preferida, dirección de protocolo de
              Internet o número único de identificación de su dispositivo,
              localización.
            </p>
          </li>
          <li>
            <p>
              Datos laborales como: Puesto, domicilio laboral, correo
              electrónico, teléfono, contrato corporativo, nombre del empleador.
            </p>
          </li>
          <li>
            <p>
              Datos de educación como: Trayectoria educativa, título, número de
              cédula profesional, especialidad, tipos de práctica, certificados
              de estudios.
            </p>
          </li>
          <li>
            <p>
              Datos de salud como: Estado de salud, historial clínico,
              enfermedades o padecimientos, información respecto a los productos
              de Bayer que adquiere, fecha de inicio y fin de tratamientos,
              esquema de administración o uso, tiempo indicado de administración
              o uso, registro de compras del producto, estudios sobre
              efectividad del medicamento.
            </p>
          </li>
          <li>
            <p>
              Datos bancarios y de facturación como: Número de cuenta, CLABE,
              RFC, datos de facturación.
            </p>
          </li>
          <li>
            <p>
              Datos personales sensibles son aquellos datos que afectan la
              esfera más íntima del titular o cuya utilización indebida puede
              dar origen a discriminación o conlleve un riesgo grave para el
              titular. Se consideran sensibles aquellos datos que puedan revelar
              aspectos como: origen racial o étnico, estado de salud,
              información genética, creencias religiosas, filosóficas y morales,
              afiliación sindical, opiniones políticas, preferencia sexual.
            </p>
          </li>
          <li>
            <p>
              Datos de menores de edad. En relación a los fines mencionados en
              este aviso de privacidad, cuando se trate de , menores de edad, o
              personas declaradas judicialmente en estado de interdicción, será
              necesario el consentimiento expreso de su tutor o Representante
              Legal.
            </p>
          </li>
        </ul>
        <h4>Usos y finalidades de los datos personales:</h4>
        <p>
          Los datos personales serán utilizados para uno o varios de los
          siguientes propósitos, de acuerdo con sus preferencias
        </p>
        <ul>
          <li><p>
            (i) Para proporcionarle actualizaciones medicas continuas y enviarle
            webinars o enviarle notificaciones sobre información médica continua
          </p></li>
          <li><p>
            (ii) Para realizar un intercambio de información científica y
            educativa
          </p></li>
          <li><p>
            (iii) Para proporcionar información corporativa e información sobre
            productos de Novo Nordisk
          </p></li>
          <li><p>
            (iv) Para cumplir con obligaciones de salud y otras obligaciones
            legales ante las autoridades sanitarias
          </p></li>
          <li><p>
            (v) Para hacer reportes a las autoridades sanitarias, relativas a
            calidad y farmacovigilancia
          </p></li>
          <li><p>
            (vi) Para la participación en programas de apoyo a pacientes,
            estudios de mercado, fines publicitarios y de prospección comercial
            y,
          </p></li>
          <li><p>
            (vii) Para registrarlo como proveedor/distribuidor/cliente, realizar
            pagos, y llevar a cabo facturación
          </p></li>
        </ul>
        <h4>Transferencias de datos personales</h4>
        <p>
          Podemos mostrar o compartir sus datos a otras afiliadas de Novo
          Nordisk y acordamos hacerlo de acuerdo a nuestra política interna de
          privacidad de datos y según las leyes y regulaciones vigentes sobre
          privacidad de datos en México. Sus datos también podrán ser
          transferidos a terceros con los que Novo Nordisk pueda cumplir con las
          finalidades descritas en él o los Avisos de Privacidad puestos a su
          disposición. Así Novo Nordisk puede proveer servicios, marketing,
          investigación de mercado o para dar soporte técnico. Estas terceras
          partes pueden estar localizadas en países que ofrecen diferentes
          niveles de protección, como por ejemplo los Estados Unidos de
          Norteamérica. La transferencia de datos a otras compañías del grupo U
          Novo Nordisk estará basado en las Reglas Corporativas para Compartir
          (BCRs, en sus siglas en inglés) de Novo Nordisk, disponibles aquí,
          siempre observando los principios de licitud, consentimiento,
          información, calidad, finalidad, lealtad, proporcionalidad y
          responsabilidad previstos en la Ley. Asimismo, cuando Novo Nordisk
          comparta sus datos personales con proveedores de servicios (terceros),
          lo hará basado en contratos de transferencia o en otros mecanismos
          aprobados de transferencia previamente acordados entre Novo Nordisk y
          los terceros. Novo Nordisk hará todo lo necesario para garantizar la
          protección de sus datos de conforma a las disposiciones establecidas
          por la Ley y demás normatividad aplicable.
        </p>
        <h4>Medidas de Seguridad</h4>
        <p>
          Novo Nordisk ha implementado y mantiene las medidas de seguridad,
          técnicas, administrativas y físicas, necesarias para proteger sus
          datos personales y evitar su daño, pérdida, alteración, destrucción o
          el uso, acceso o tratamiento no autorizado.
        </p>
        <h5>
          ¿Qué tecnologías de rastreo utilizamos en nuestro portal de Internet?
        </h5>
        <p>
          Le informamos que en nuestra página de Internet utilizamos cookies,
          web beacons y otras tecnologías a través de las cuales es posible
          monitorear su comportamiento como usuario de Internet, así como
          brindarle un mejor servicio y experiencia de usuario al navegar en
          nuestra página. Los datos personales que obtenemos de estas
          tecnologías de rastreo son los siguientes: • Tipo de navegador y
          sistema operativo • Páginas de Internet visitadas • Vínculos seguidos
          • La dirección IP • Sitios visitados antes de ingresar, mismos que
          utilizamos para fines estadísticos y de monitoreo de calidad en el
          servicio. Asimismo, le informamos que compartiremos estos datos con
          los terceros designados para brindar servicios del programa ya
          mencionados. informamos que en nuestra página de Internet utilizamos
          cookies, web beacons y otras tecnologías similares a través de la cual
          sea posible monitorear su comportamiento en Internet.
        </p>
        <h5>DERECHOS ARCO.</h5>
        <p>
          El titular de datos personales puede ejercitar los derechos de acceso,
          rectificación, cancelación y oposición (derechos “ARCO”) respecto a
          sus datos personales. Asimismo, dicho titular puede limitar el uso o
          divulgación de sus datos personales y revocar el consentimiento que
          haya otorgado. Para poder hacer valer los derechos ARCO, es necesario
          que el Titular de los Datos Personales realice una solicitud con los
          requisitos que se detallan a continuación o mediante el envío del
          “formato sugerido” mediante las vías citadas adelante. (i) el nombre y
          domicilio o correo electrónico del Titular para comunicarle la
          respuesta a su solicitud; (ii) los identificación oficial del Titular,
          o en su caso, la representación legal de quien actúe en su nombre;
          (iii) la descripción clara y precisa de los Datos Personales respecto
          de los que el Titular busca ejercer alguno de los derechos antes
          mencionados; (iv) especificar claramente si la solicitud es de acceso,
          rectificación, cancelación u oposición; (vi) el motivo de la
          solicitud; y (vii) las modificaciones a realizarse en caso de que la
          solicitud sea para la rectificación de Datos Personales.
        </p>
        <h5>
          Esta solicitud deberá de hacerla llegar a Novo Nordisk por cualquiera
          de los siguientes medios:
        </h5>
        <ul>
          <li><p>
            Vía correo electrónico a la dirección
            <a href="mailto:datos.personales@novonordisk.com">datos.personales@novonordisk.com</a> </p>
          </li>
          <li><p>
            Vía correo postal y/o personalmente en las oficinas de Novo Nordisk
            México, S.A. de C.V., al domicilio que se menciona en el presente
            aviso. El procedimiento que Novo Nordisk adoptará una vez que haya
            recibido la solicitud consta en lo siguiente: Hará de su
            conocimiento en un plazo máximo de 20 (veinte) días naturales,
            contados a partir de la fecha en que haya recibido la solicitud, la
            determinación adoptada, y en caso de que la misma resulte
            procedente, se hará efectiva dentro de los 15 (quince) días
            naturales siguientes a la fecha en que se comunique su procedencia.</p>
          </li>
        </ul>
        <h5>DE LAS MODIFICACIONES AL AVISO DE PRIVACIDAD.</h5>
        <p>
          El presente Aviso de Privacidad podrá ser modificado en cualquier
          momento. Ante algún cambio sustancial o relevante mediante, será
          comunicado a través de nuestro sitio de internet
          <a href="http://www.novonordisk.com.mx/" target="_blank">http://www.novonordisk.com.mx/</a>
        </p>
        <h5>INFORMACIÓN PARA REPORTAR UN EVENTO ADVERSO</h5>
        <p>
          En relación al reporte de eventos adversos que regulatoriamente
          estamos obligados a documentar y a rendir, recabaremos los siguientes
          datos, no siendo necesario recolectar su consentimiento expreso:
        </p>
        <ul>
          <li><p>
            1. Un dato demográfico del paciente, basta con mencionar si es
            hombre o mujer, sexo, edad o iniciales;
          </p></li>
          <li><p>
            2. Un evento adverso; es decir, descripción de los signos y síntomas
            presentado por el paciente.
          </p></li>
          <li><p>
            3. Un medicamento, en este caso el paciente tiene que estar tomando
            un medicamento de Novo Nordisk;
          </p></li>
          <li><p>
            4. Un informante, es decir, la persona que nos está informando del
            evento, esta persona tiene que identificarse con nombre y datos de
            contacto, que puede ser un número de teléfono, correo electrónico o
            dirección.
          </p></li>
        </ul>
        <p>
          <strong>DE LA ACEPTACIÓN DE LOS TÉRMINOS.</strong> En virtud del
          presente documento, usted manifiesta su consentimiento para el
          tratamiento de los datos personales proporcionados, de acuerdo a los
          términos y condiciones del presente Aviso de Privacidad puesto a su
          disposición.
        </p>
        <p>
          Si usted desea ejercer sus derechos ARCO o para cualquier otra
          cuestión, contacte con nuestro responsable de privacidad de Datos
          (“Data Privacy Officer”)
        </p>
        <p>
          <strong>Contacto:</strong> Responsable de sitio web de Novo Nordisk
          México: novomexico@novonordisk.com; Tel. +52 (55) 5002 6686
        </p>
        <h5>Fecha última actualización 30 de octubre de 2020</h5>
        <p>
          Acepta los términos que describe este Aviso de Privacidad y doy el
          consentimiento para que mi información, datos personales y sensibles
          sean utilizados para los efectos establecidos en el presente aviso de
          privacidad.
        </p>
      </section>
      <div class="d-flex flex-column my-5 login_container">
          <router-link to="/login" class="btn mt-4 btn-primary-novo">Aceptar y continuar</router-link>
        </div>
    </template>
  </register-layout>
</template>

<script>
import RegisterLayout from "./layouts/RegisterLayout";
export default {
  components: {
    RegisterLayout,
  },
  data: () => {
    return { bgRegister: "bgRegister" };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/css/colors";
.privacity-content{
  padding-top: 3rem;
  p{
    text-align: justify;
  }
  h4, h5{
    color: $color-primary;
    margin-top: 2rem;
  }
  ul{
    list-style: none;
  }
 
}
</style>