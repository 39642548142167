<template>
  <result-layout :textLayoutResult="cssTextBackground" :bglayout="cssclasback">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>

    <template v-slot:mainContent>
      <div class="px-0 pt-5">
        <div class="d-flex justify-content-center">
          <img class="img-fluid my-4" src="../assets/img/heart.svg" alt="" />
        </div>
        <div class="d-flex justify-content-around">
          <h1>¡Correcto!</h1>
        </div>
        <div class="d-flex justify-content-center">
          <p class="text-correcteasy-description">
            Vas por buen camino, <br />
            sigue así.
          </p>
        </div>
        <br />
        <br />
        <br />
        <div class="d-flex flex-column px-novo">
          <button type="button" @click="handleNextQuestion"
            class="btn btn-primary-novo text-uppercase my-3">
            Siguiente pregunta
          </button>
          <button type="button" @click="handleNewLevel"
            class="btn btn-secondary-novo text-uppercase my-3">
            Otro nivel
          </button>
        </div>
        <br />
        <br />
        <br />
      </div>
    </template>

    <template v-slot:footerContent>
      <footer-shop></footer-shop>
    </template>
  </result-layout>
</template>
<script>
import ResultLayout from "./layouts/ResultLayout";
import HeaderGame from "./shared/Header";
import FooterShop from "./shared/FooterAlt";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ResultLayout, HeaderGame, FooterShop,
  },
  data: () => {
    return {
      bgRegister: "bg-win", 
      cssTextBackground:"bg-txt-congrats"
    };
  },
  computed:{
    ...mapState({ 
      category: (state) => state.game.categorySlctd
    }),
    cssclasback(){
      if(this.category.name.toLowerCase() === 'fácil')
        return 'facil';
      else
        return this.category.name.toLowerCase();
    }
  },
   methods: {
    ...mapActions(['updateTryAgain']),
    handleNextQuestion(){
      this.updateTryAgain(false);
      this.$router.push('/quiz');
    },
    handleNewLevel(){
      // this.updateShowAssitant(true);
      this.updateTryAgain(false);
      this.$router.push('/start');
    }
  }
};
</script>
<style lang="scss">
@import "../assets/css/colors";
.text-correcteasy-description {
  font-size: 2rem;
  color: $color-primary;
  text-align: center;
}
</style>