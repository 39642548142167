<template>
  <transition name="fade">  
    <router-view /> 
  </transition>
</template>
<script>
// import { mapActions } from "vuex";

export default {
  name: 'App',
  methods: {
    // ...mapActions({
    //   // getData: "getData",
    // }),
  },
  created() {
    // this.initializeCart(this.$store);
  },
}
</script>
