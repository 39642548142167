<template>
  <success-register-layout>
    <template v-slot:mainContent>
      <section class="d-flex flex-column justify-content-center mt-5 pt-5">
        <h1 class="text-center">Registro exitoso</h1>
        <h4 class="text-center txt-color-primary">
          Mira este vídeo y conoce como jugar.
        </h4>
        <a href="#" class="my-5 text-center" @click.prevent="handleShowVideo">
          <img src="../assets/img/boton-play.svg" alt="" />
        </a>
        <modal-novo
          :showModal="viewmodal"
          @close="viewmodal = false"
        ></modal-novo>
        <h4 class="text-center txt-color-primary mt-5">
          Sigue estos sencillos pasos para finalizar tu registro.
        </h4>
        <ul class="list-group py-5 steps-to-success">
          <li class="list-group-item">
            <div class="step-item">
              <span class="badge badge-novo">1</span>
              <h3>Revisa tu bandeja de entrada</h3>
            </div>
          </li>
          <li class="list-group-item">
            <div class="step-item">
              <span class="badge badge-novo">2</span>
              <h3>Da clic en el enlace</h3>
            </div>
          </li>
          <li class="list-group-item">
            <div class="step-item">
              <span class="badge badge-novo">3</span>
              <h3>Ingresa tu cuenta y contraseña</h3>
            </div>
          </li>
          <li class="list-group-item">
            <div class="step-item">
              <span class="badge badge-novo">4</span>
              <h3>Disfuta de NovoClue</h3>
            </div>
          </li>
        </ul>
        <div class="d-flex flex-column my-5 login_container">
          <router-link to="/" class="btn btn-secondary-novo">Home</router-link>
        </div>
      </section>
    </template>
  </success-register-layout>
</template>

<script>
import SuccessRegisterLayout from "./layouts/SuccessRegisterLayout";
import ModalNovo from "./GameComponents/ModalNovo";

export default {
  data: () => {
    return {
      viewmodal: false,
    };
  },
  components: {
    SuccessRegisterLayout,
    ModalNovo,
  },
  methods: {
    handleShowVideo() {
      this.viewmodal = true;
    },
  },
};
</script>