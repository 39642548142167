<template>
  <main-layout :bglayout="'bgRegister'">
    <template v-slot:headerContent>
      <header-game></header-game>
    </template>
    <template v-slot:mainContent>
      <section class="novo-cards px-md-5">
        <h4 class="txt-color-primary">Elige un nivel de dificultad</h4>
        <carousel-3d
          v-if="loaded"
          :height="660"
          :width="370"
          :controls-prev-html="''"
          :controls-next-html="''"
          :controls-visible="true"
        >
          <slide v-for="(c, index) in categories" :key="index" :index="index">
            <card-category
              :id="c.id"
              :class="`bg-card-${index}`"
              :title="c.name"
              :image="`crown-${index}`"
              :description="c.description"
              @selectedCategory="handleSelectedtCategory"
            >
            </card-category>
          </slide>
        </carousel-3d>
      </section>
      <foqui-novo
        v-if="showAssistant && loadedSettings"
        :contentTxt="settings.initialMessage"
        :title="txtTitle"
        :show="showOverlay"
        @close="handleCloseAssistant"
      ></foqui-novo>
    </template>

    <template v-slot:footerContent>
      <footer-game></footer-game>
    </template>
  </main-layout>
</template>
<script>
import MainLayout from "./layouts/MainLayout";
import HeaderGame from "./shared/Header";
import FoquiNovo from "./GameComponents/FoquiNovo";
import { Carousel3d, Slide } from "vue-carousel-3d";
import FooterGame from "./shared/Footer";
import CardCategory from "./GameComponents/CardCategory";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MainLayout, HeaderGame, FooterGame, CardCategory, 
    Carousel3d, Slide, FoquiNovo,
  },
  data: () => {
    return {
      // txtContent:
      //   "Soy tu asistente NovoClue, te estaré informando sobre las reglas y actualizaciones de premios.",
      showOverlay: true,
      txtTitle: "¡Hola!",
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.game.categories,
      selectedCat: (state) => state.game.categorySlctd,
      user: (state) => state.auth.identifiedUser,
      showAssistant: (state) => state.game.showFoqui,
      settings: (state) => state.game.settingsGame,
    }),
    loaded() {
      return this.categories.length > 0 ? true : false;
    },
    loadedSettings(){
      return this.settings ? true :false;
    }
  },
  methods: {
    ...mapActions([
      "getCategories",
      "selectedCategory",
      "getWallet",
      "updateShowAssitant",
      "getConfigurationsGame",
    ]),
    handleSelectedtCategory(catId) {
      const cat = this.categories.find((c) => c.id === catId);
      this.selectedCategory(cat);
      if (this.selectedCat.id > 0) {
        this.$router.push("/quiz");
      }
    },
    handleCloseAssistant() {
      this.updateShowAssitant(false);
      this.showOverlay = false;
    },
  },
  created() {
    this.getConfigurationsGame();
    this.getWallet(this.user.id);
    this.getCategories();
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors";
.iwantthis{
  background-image: url(../assets/img/handover.svg); 
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &:hover{
    background-image: url(../assets/img/hand.svg); 
  }
}
</style>