<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Crear Productos</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Producto</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="name">Nombre <span class="obligatory">*</span> </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="100"
                  v-model="name"
                  placeholder="Nombre del Producto"
                />           
              </div>
              <div class="form-group col-md-4">
                <label for="price">Precio <span class="obligatory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="price"
                  v-model="price"
                  placeholder="Precio del Producto"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="sku">Sku</label>
                <input
                  type="text"
                  class="form-control"
                  id="sku"
                  v-model="sku"
                  placeholder="Sku del Producto"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="description">Descripción</label>
              <input
                type="text"
                class="form-control"
                id="description"
                maxlength="300"
                v-model="description"
                placeholder="Descripción del Producto"
              />
            </div>
            <div class="form-group">
              <label for="image">Imagen (620 x 700px) <span class="obligatory">*</span></label>
              <input
                type="file"
                class="form-control"
                id="image"
                ref="images"
                accept="image/png, image/jpeg, image/jpg"
                @change="onFileSelected"
              />
            </div>
            <button 
              @click="handleAddProduct()"
              type="button" class="btn btn-primary">Crear</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },

  data: () => {
    return {
      name: "",
      price: "",
      sku: "",
      description: "",
      image: "",
      imagew: "",
      imageh: "",
    };
  },
  validations: {
    name: { required },
    price: { required },
    image: { required },
  },
  methods: {
    ...mapActions(["addProduct"]),
    async handleAddProduct() {
      if (!this.$v.$invalid) {
        this.$fire({
            text: "Espera un momento.",
            showCancelButton: false,
            showConfirmButton: false
        });
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('price', this.price);
        formData.append('sku', this.sku);
        formData.append('description', this.description);
        formData.append('image', this.image);
        const created = await this.addProduct(formData);
        if (created) {
            this.$refs.images.value=null;
            this.image = null;
            this.$alert("Producto Creado.");
            this.$router.push({
                name: "dashboardproducts",
            });
        } else {
            console.error("Algo falló");
        }
      }else{
        this.$alert("Debes llenar los campos marcados con *.");
      }
    },
    async onFileSelected(event){
      this.image = event.target.files[0];

      const fileType = this.image.type;
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      var FileSize = this.image.size / 1024 / 1024;
      if (!validImageTypes.includes(fileType)) {
          this.$alert("El tipo de archivo seleccionado no es válido.");
          this.image = "";
          this.$refs.images.value=null;
      }else if(FileSize > 2){
        this.$alert("El peso de la imagen no es permitido.");
        this.image = "";
        this.$refs.images.value=null;
      }else{
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            this.imagew = img.width;
            this.imageh = img.height;
            if (this.imagew != '620' || this.imageh != '700') {
              this.$alert("Las dimensiones de la imagen no son permitidas.");
              this.image = "";
              this.$refs.images.value=null;
            }
          }
          img.src = evt.target.result;
        }

      }
    }
  },

};

</script>