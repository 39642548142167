<template>
  <transition name="modal">
    <div class="modal-mask-avatar" v-if="showModal">
      <div class="modal-wrapper-avatar">
        <div class="modal-container-avatar">
          <div class="close-avatar-modal">
            <a @click="$emit('close')">
              <vue-fontawesome
                class="ml-3"
                icon="times-circle"
                size="4"
                :style="{ color: '#003878' }"
              ></vue-fontawesome>
            </a>
          </div>
          <div class="modal-body-avatar">
            <slot name="body">
              <div
                class="d-flex justify-content-center align-items-center flex-column content-choise-avatar"
              >
                <h2 class="my-3">Elige una nuevo avatar</h2>
                <div class="avatars">
                  <label v-for="(ava, i) in avatarImgs" :key="i">
                    <input
                      type="radio"
                      v-model="avatar"
                      :value="ava"
                      checked
                    />
                    <span class="content-avatar">
                      <img
                        class="img-responsive img-avatar"
                        :src="require(`@/assets/avatars/${ava}.svg`)"
                      />
                    </span>
                  </label>
                </div>
                <button
                  type="button"
                  @click="handleSelectedAvatar"
                  name="button"
                  class="btn mt-3 btn-primary-novo"
                >
                  Aceptar
                </button>
                <a @click="$emit('close')" class="btn mb-3 mt-4 btn-secondary-novo">
                  Cancelar
                </a>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
// import carousel from "vue-owl-carousel2";

export default {
  // components: { carousel },
  props: {
    showModal: Boolean,
  },
  data: () => {
    return {
      avatarImgs: ["artboard_1", "artboard_2", "artboard_3", "artboard_4", "artboard_5", "artboard_6", "artboard_7", "artboard_8", "artboard_9", "artboard_10", "artboard_11", "artboard_12", "artboard_13", "artboard_14", "artboard_15", "artboard_16", "artboard_17", "artboard_18", "artboard_19", "artboard_20"],
      fullPathsAvs: [],
      avatar: "",
    };
  },
  methods:{
    handleSelectedAvatar(){
      this.$emit('close', this.avatar)
    }
  },
  mounted() { },
};
</script>
<style lang="scss" >
@import "../../assets/css/colors";
.modal-mask-avatar {
  
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  display: table;
  transition: opacity 0.3s ease;
}



.close-avatar-modal {
  position: relative;
  a {
    position: absolute;
    right: -25px;
    top: -18px;
  }
}
.modal-body-avatar {
  // margin: 20px 0;
  padding: 0;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>