<template>
  <profile-layout>
    <template v-slot:headerContent>
      <header class="py-3 header-profile">
        <h2>Tu perfil</h2>
      </header>
    </template>
    <template v-slot:mainContent>
       <slide-avatars
          :showModal="viewmodal"
          @close="handleCloseModal"
        ></slide-avatars>
      <section class="profile-novo" v-if="loaded">
        <div class="d-flex flex-column mb-4">
          <img class="img-thumbnail" :src="pathAvatar" />
          <button 
            @click="handleAvatarmodal"
            class="btn btn-outline-prymary-novo flex-aling-item">
            <vue-fontawesome icon="id-badge" size="4" color="#003878"></vue-fontawesome>
          </button>
        </div>
        
        <h3>{{ user.name }} {{ user.firstName }}</h3>
        <div class="card text-center w-100 my-4">
          <div class="card-header bg-card-1">Detalles generales</div>
          <div class="card-body">
            <table class="table">
              <tr>
                <td class="text-left">Coronas obtenidas</td>
                <td class="text-left">{{profileEdit.crown}}</td>
              </tr>
              <tr>
                <td class="text-left">Puntos obtenidos</td>
                <td class="text-left">{{profileEdit.points}}</td>
              </tr>
              <tr>
                <td class="text-left">Puntos Gastados</td>
                <td class="text-left">0</td>
              </tr>
              <tr>
                <td class="text-left">Compras hechas</td>
                <td class="text-left">0</td>
              </tr>
              <tr>
                <td class="text-left">Preguntas respondidas</td>
                <td class="text-left">{{profileEdit.questionsAnswered}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="form-question-wrap w-100">
          <form class="px-md-3 px-lg-4">
            <div class="mb-1 mt-5">
              <label for="username" class="form-label form-label-novo"
                >Nombre(s) <span class="obligatory">*</span></label
              >
              <input
                id="username"
                type="text"
                v-model="profileEdit.name"
                class="form-control form-control-novo"
                value=""
                placeholder="Nombre"
              />
            </div>
            <div class="mb-3">
              <label for="lastname" class="form-label form-label-novo"
                >Apellidos <span class="obligatory">*</span></label
              >
              <input
                id="lastname"
                type="text"
                v-model="profileEdit.firstName"
                class="form-control form-control-novo"
                value=""
                placeholder="Apellidos"
              />
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label form-label-novo"
                >Teléfono</label
              >
              <input
                id="phone"
                type="tel"
                maxlength="10"
                @keydown="handleOnlyNumber"
                v-model="profileEdit.phone"
                class="form-control form-control-novo"
                value=""
                placeholder="Teléfono"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label form-label-novo"
                >Correo electrónico <span class="obligatory">*</span></label
              >
              <input
                id="email"
                type="email"
                v-model="profileEdit.email"
                class="form-control form-control-novo"
                value=""
                placeholder="Correo electrónico"
              />
            </div>
            <div class="d-flex flex-column my-5 login_container">
              <button type="button" 
                @click="handleUpdateUser"
                name="button" 
                class="btn btn-primary-novo">
                Guardar
              </button>
              <router-link to="/start" class="btn mt-4 btn-secondary-novo"
                >Regresar</router-link
              >
            </div>
          </form>
        </div>
      </section>
    </template>
    <template v-slot:footerContent> </template>
  </profile-layout>
</template>

<script>
import ProfileLayout from "./layouts/ProfileLayout";
import SlideAvatars from './GameComponents/SlideAvatars';
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    ProfileLayout, SlideAvatars
  },
  data: () => {
    return {
      profileEdit: {},
      pathAvatar:"",
      viewmodal: false,
    };
  },
  validations: {
    profileEdit:{
      name: { required },
      firstName: { required },
      email: { required, email }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.identifiedUser,
      userData: (state) => state.game.userData,
    }),
    loaded() {
      return this.userData ? true : false;
    },
  },
  methods: {
    ...mapActions([ "setLoadingState", "getConfigsUser", "updateConfigsUser"]),
    handleOnlyNumber(event) {
      const char = String.fromCharCode(event.keyCode);
      if (event.keyCode != 8) {
        if (!/[0-9]/.test(char)) {
          event.preventDefault();
        }
      }
    },
    handleAvatarmodal(){
      this.viewmodal = true;
    },
    async handleUpdateUser(){
      if (!this.$v.$invalid) {
        console.log(this.profileEdit);
        this.setLoadingState(true);
        const res = await this.updateConfigsUser(this.profileEdit);
        this.setLoadingState(false);
        if(res){
          this.$iosAlert({
            title: 'Correcto',
            text: 'Datos actualizados',
          });
        }else{
          this.$iosAlert({
            title: 'Error',
            text: 'Lo sentimos, alog salió mal. Intenta más tarde.',
          });
        }
      }
    },
    async handleCloseModal(avatarName){
      console.log(avatarName);
      if(avatarName){
        this.profileEdit.avatar = `${avatarName}.svg`;
        await this.handleUpdateUser();
        this.setAvatarInGame();
      }
      this.viewmodal = false;
    },
    setAvatarInGame(){
      let images = require.context('../assets/avatars', false, /\.svg$/);
      this.pathAvatar = images(`./${this.profileEdit.avatar}`);
    }
  },
  async mounted() {
    await this.getConfigsUser(this.user.id);
    this.profileEdit = Object.assign({}, this.userData);
    this.setAvatarInGame();
  },
};
</script>