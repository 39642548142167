<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Productos Actuales</h1>
        <h3>Productos</h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Productos</h2>
        <div class="additional-btn">
          <a @click.prevent="createProduct()" class="btn btn-success mr-2"><vue-fontawesome icon="plus"></vue-fontawesome></a>
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Sku</th>
                <th scope="col">Precio</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="q in products" :key="q.id">
                <td>{{ q.id }}</td>
                <td>{{ q.name }}</td>
                <td>{{ q.sku }}</td>
                <td>{{ q.price }}</td>
                <td>
                  <a
                    @click.prevent="handleEdithProduct(q.id)"
                    class="btn btn-primary mr-2"
                  >
                    <vue-fontawesome icon="edit"></vue-fontawesome>
                  </a>
                  <a
                    @click.prevent="handleDeleteProduct(q.id)"
                    class="btn btn-danger"
                  >
                    <vue-fontawesome icon="trash"></vue-fontawesome>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <paginate
          :page-count="pageCount"
          :click-handler="handleClickPage"
          :prev-text="'Anterior'"
          :next-text="'Siguiente'"
          :page-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :page-link-class="'page-link'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import Paginate from 'vuejs-paginate';

export default {
  components: {
    DashboardLayout,
    SideBar,  TopBarAdmin, Paginate
  },
  computed: {
    ...mapState({
      products: (state) => state.dashboardproducts.products,
      pageCount: (state) => state.dashboardproducts.metaproduct.lastpage,
    }),
  },
  methods: {
    ...mapActions(["getProducts","deletedProduct"]),
    onDownload(payload) {
      alert(payload.msg);
    },
    createProduct(){
      this.$router.push({
        name: "createproduct",
      });
    },
    handleEdithProduct(idProduct) {
      this.$router.push({
        name: "detailproduct",
        params: { id: idProduct },
      });
    },
    handleDeleteProduct(idProduct) {
      this.deletedProduct(idProduct);
      this.$alert("Producto Eliminado.");
    },
    handleClickPage(pageNum){
      this.getProducts(pageNum);
    }
  },
  mounted() {
    this.getProducts(1);
  },
};
</script>