<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Cargar Productos</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Productos</h2>
        <div class="additional-btn">
           <a href="http://jesusrizo.com/raymundonovo/administrador/storage/app/public/products/FORMATO CARGA DE PRODUCTOS.xlsx" target="_blank" style="color:#fff;" class="btn btn-success mr-2" >Descarga el template</a> 
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <div class="form-group">
            <div v-bind:class="alertcontent.type" role="alert" v-if="isShowLoad">
                <strong>{{alertcontent.title}}</strong>
            </div>
          </div>
          <form role="form" enctype="multipart/form-data">
            <div class="form-group">
              <label for="name">Archivo <span class="obligatory">*</span> </label>
              <input
                type="file"
                class="form-control"
                id="fileproducts"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref="inputFile"
                v-on:change="fileproducts"
              />
            </div>
            <button @click="formSubmitUploadProducts()"
              type="button" class="btn btn-primary">
                Verificar
            </button>
          </form>
        </div>
        <br>
        <div id="basic-form">
            <div v-if="tableresponse"><span v-html="tableresponse"></span></div>
            <div class="form-group" align="center">
                <button type="button" v-if="isShowBtnLoad" v-on:click="formAddProducts" class="btn btn-success">Cargar</button>
            </div>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        DashboardLayout,
        SideBar,
        TopBarAdmin,
    },
    data: () => {
        return {
            file: "",
            tableresponse: '',
            isShowLoad: '',
            isShowBtnLoad: '',
            alertcontent: {},
        };
    },
    validations: {
        file: { required },
    },
    methods: {
        ...mapActions(["addProducts","formaddProductsUpload"]),
        fileproducts(e){
            this.file = e.target.files[0];
            if(this.file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                this.$alert("El tipo de archivo seleccionado no es válido.");
                this.file = null;
                this.$refs.inputFile.value=null;
            }
        },
        async formSubmitUploadProducts() {
            if (!this.$v.$invalid) {
                let content = {
                    title: 'Espere un momento...',
                    type: 'alert alert-warning'
                };
                this.alertcontent = content;
                this.isShowLoad = true;
                let formData = new FormData();
                formData.append('file', this.file);
                try {
                    let uploaded = await this.addProducts(formData);
                    this.tableresponse = uploaded.data.mensaje;
                    let content = {
                        title: 'Diríjase a la parte inferior de la tabla',
                        type: 'alert alert-primary'
                    };
                    this.alertcontent = content;
                    this.isShowLoad = true;
                    if (uploaded.data.error == false) {
                      this.isShowBtnLoad = true;
                    } else {
                      this.isShowBtnLoad = '';
                    }
                } catch (error) {
                    let content = {
                        title: 'Verifique el archivo cargado',
                        type: 'alert alert-danger'
                    };
                    this.alertcontent = content;
                    this.isShowLoad = true;
                }
            }else{
                this.$alert("Debes llenar los campos marcados con *.");
            }
        },

        async formAddProducts(){
            this.isShowLoad = false;
            let content = {
                title: 'Espere un momento...',
                type: 'alert alert-warning'
            };
            this.alertcontent = content;
            this.isShowLoad = true;
            this.isShowBtnLoad = false;
            let formData = new FormData();
            formData.append('file', this.file);
            try {
                await this.formaddProductsUpload(formData);
                this.isShowLoad = false;
                let content = {
                    title: 'Archivo cargado con éxito',
                    type: 'alert alert-success'
                };
                this.alertcontent = content;
                this.isShowLoad = true;
                this.tableresponse = '';
                this.isShowBtnLoad = false;
                this.file = null;
                this.$refs.inputFile.value=null;
            } catch (error) {
                this.isShowLoad = false;
                let content = {
                    title: 'Ha ocurrido un error durante la carga de preguntas',
                        type: 'alert alert-danger'
                };
                this.alertcontent = content;
                this.isShowLoad = true;
            }
        },
    },
};
</script>