<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Editar Productos</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Producto</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="name">Nombre <span class="obligatory">*</span> </label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  maxlength="100"
                  v-model="dataToEdit.name"
                  placeholder="Nombre del Producto"
                />           
              </div>
              <div class="form-group col-md-4">
                <label for="price">Precio <span class="obligatory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="price"
                  v-model="dataToEdit.price"
                  placeholder="Precio del Producto"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="sku">Sku</label>
                <input
                  type="text"
                  class="form-control"
                  id="sku"
                  v-model="dataToEdit.sku"
                  placeholder="Sku del Producto"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="description">Descripción</label>
              <input
                type="text"
                class="form-control"
                id="description"
                maxlength="300"
                v-model="dataToEdit.description"
                placeholder="Descripción del Producto"
              />
            </div>
            <div class="form-group" align="center">
              <img :src="'http://localhost:8000/storage/' + dataToEdit.image" style="width:25%;" alt="">
            </div>
            <div class="form-group">
              <label for="image">Imagen (620 x 700px)</label>
              <input
                type="file"
                class="form-control"
                id="image"
                ref="images"
                accept="image/png, image/jpeg, image/jpg"
                @change="onFileSelected"
              />
            </div>
            <button 
              @click="handleSaveChanges()"
              type="button" class="btn btn-primary">Guardar cambios</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  computed: {
    ...mapState({
      detail: (state) => state.dashboardproducts.product,
      success: (state) => state.dashboardproducts.success,
    }),
  },
  data() {
    return {
      dataToEdit: {},
      image2: "",
      imagew: "",
      imageh: "",
    };
  },
  validations: {
    dataToEdit : {
      name: { required },
      price: { required },
    }
  },
  methods: {
    ...mapActions(['getDetailProduct', 'updateProductDetail']),
    async handleSaveChanges(){
      if (!this.$v.$invalid) {
        let formData2 = new FormData();
        formData2.append('id', this.dataToEdit.id);
        formData2.append('name', this.dataToEdit.name);
        formData2.append('price', this.dataToEdit.price);
        formData2.append('sku', this.dataToEdit.sku);
        formData2.append('description', this.dataToEdit.description);
        formData2.append('image', this.image2);
        const res = await this.updateProductDetail(formData2);
        if(res){
          this.$refs.images.value=null;
          this.$alert("Producto Actualizado.");
          await this.getDetailProduct(this.$route.params.id);
          this.dataToEdit = Object.assign({}, this.detail);
        } else {
            console.error("Algo falló");
        }
      }else{
        this.$alert("Debes llenar los campos marcados con *.");
      }
    },
    async onFileSelected(event){
      this.image2 = event.target.files[0];

      const fileType = this.image2.type;
      const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      var FileSize = this.image2.size / 1024 / 1024;
      if (!validImageTypes.includes(fileType)) {
          this.$alert("El tipo de archivo seleccionado no es válido.");
          this.image2 = "";
          this.$refs.images.value=null;
      }else if(FileSize > 2){
        this.$alert("El peso de la imagen no es permitido.");
        this.image2 = "";
        this.$refs.images.value=null;
      }else{
        let reader = new FileReader();
        reader.readAsDataURL(this.image2);
        reader.onload = evt => {
          let img = new Image();
          img.onload = () => {
            this.imagew = img.width;
            this.imageh = img.height;
            if (this.imagew != '620' || this.imageh != '700') {
              this.$alert("Las dimensiones de la imagen no son permitidas.");
              this.image2 = "";
              this.$refs.images.value=null;
            }
          }
          img.src = evt.target.result;
        }
      }


    }
  },
  async created(){
    await this.getDetailProduct(this.$route.params.id);
    this.dataToEdit = Object.assign({}, this.detail);
  },
};
</script>