<template>
   <div class="landing-novo" id="page-top">
     <header-site id="begin"/>
     <main-menu-site />
     <rules-site /> 
     <register-site/>
     <top-five-site />
     <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)-->
        <div class="scroll-to-top d-lg-none position-fixed">
            <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top"><i class="fa fa-chevron-up"></i></a>
        </div>
   </div>
</template>
<script>
import HeaderSite from "./site/HeaderSite";
import MainMenuSite from "./site/MainMenu";
import RulesSite from "./site/RulesSite";
import RegisterSite from "./site/RegisterSite";
import TopFiveSite from "./site/TopFiveSite";


export default {
  components: {
    HeaderSite, MainMenuSite, RulesSite, RegisterSite, TopFiveSite
  },
  mounted(){ }
}
</script>
<style >
.pn-top-3{
  padding-top: 5rem;
}
</style>