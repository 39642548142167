<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Editar preguntas</h1>
        <h3></h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Preguntas</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div id="basic-form">
          <form role="form">
            <div class="form-group">
              <label for="question">Pregunta</label>
              <input
                type="text"
                class="form-control"
                id="question"
                v-model="anwsToEdit.question"
                placeholder="Pregunta completa"
              />
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmail4">Respuesta Correcta</label>
                <select 
                  v-model="anwsToEdit.correct_ans"
                  class="form-control">
                  <option :selected="anwsToEdit.correct_ans == 'A'" value="A">A</option>
                  <option :selected="anwsToEdit.correct_ans == 'B'" value="B">B</option>
                  <option :selected="anwsToEdit.correct_ans == 'C'" value="C">C</option>
                  <option :selected="anwsToEdit.correct_ans == 'D'" value="D">D</option>
                </select>
              </div>
              <div class="form-group col-md-8">
                <label for="inputPassword4">Categoría</label>
                <select class="form-control">
                  <option 
                    v-for="c in categories" 
                    :selected="c.id == anwsToEdit.category_id"
                    :key="c.id">{{c.name}}</option>
                </select>
              </div>
            </div>
            <br>
            <h4>Respuestas:</h4>
            <div v-for="(ans, index) in anwsToEdit.answers" :key="ans.id" class="form-group">
              <label for="answer1">Respuesta <strong>{{options[index]}}</strong></label>
              <input
                type="text"
                class="form-control"
                id="answer1"
                placeholder="Ingresa la respuesta"
                :value="ans.content"
              />
            </div>
           
            <button 
              @click="handleSaveChanges()"
              type="button" class="btn btn-primary">Guardar cambios</button>
          </form>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  computed: {
    ...mapState({
      detail: (state) => state.dash.question,
      categories: (state) => state.dash.cats,
      options: (state) => state.dash.options,
      success: (state) => state.dash.success,
    }),
   
  },
  data() {
    return {
      anwsToEdit: {}
    };
  },
  methods: {
    ...mapActions(['getDetailQuestion', 'getCats', 'updateQuizDetail']),
    async handleSaveChanges(){
      console.log(this.anwsToEdit);
      const res = await this.updateQuizDetail(this.anwsToEdit);
      if(res){
        this.$alert("Pregunta Actualizada.");
      }
    }
  },
  async created(){
    // this.$store.dispatch('updateIdQuestionToEdit', this.$route.params.id)
    this.getCats();
    await this.getDetailQuestion(this.$route.params.id);
    this.anwsToEdit = Object.assign({}, this.detail);
    // console.log(this.anwsToEdit);
  },
};
</script>