<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin/>
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-content padding">
        <card-dash />
      </div>
      <div class="row">
        <!-- <h2>Graficos</h2> -->
        <div class="col-md-6 portlets">
          <div class="widget">
            <div class="widget-header">
              <h2>Preguntas respondidas por mes</h2>
              <div class="additional-btn">
                <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
                <a href="#" class="widget-toggle"
                  ><i class="icon-down-open-2"></i
                ></a>
                <a href="#" class="widget-close"
                  ><i class="icon-cancel-3"></i
                ></a>
              </div>
            </div>
            <div class="widget-content padding">
              <GChart
                type="ColumnChart"
                :data="chartQuestions"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 portlets">
          <div class="widget">
            <div class="widget-header">
              <h2>Usuarios mas activos (Históricamente)</h2>
              <div class="additional-btn">
                <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
                <a href="#" class="widget-toggle"
                  ><i class="icon-down-open-2"></i
                ></a>
                <a href="#" class="widget-close"
                  ><i class="icon-cancel-3"></i
                ></a>
              </div>
            </div>
            <div class="widget-content padding">
              <GChart
                type="ColumnChart"
                :data="chartData"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 portlets">
          <div class="widget">
            <div class="widget-header">
              <h2>Preguntas más respondidas por nivel</h2>
              <div class="additional-btn">
                <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
                <a href="#" class="widget-toggle"
                  ><i class="icon-down-open-2"></i
                ></a>
                <a href="#" class="widget-close"
                  ><i class="icon-cancel-3"></i
                ></a>
              </div>
            </div>
            <div class="widget-content padding">
              <GChart
                :settings="{ packages: ['bar'] }"
                :data="chartDataBar"
                :options="chartOptionsBar"
                :createChart="(el, google) => new google.charts.Bar(el)"
                @ready="onChartReady"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>
<script>
import SideBar from "./Dashboard/SideBar";
import TopBarAdmin from "./Dashboard/TopBarAdmin";
import CardDash from "./Dashboard/CardDash";
// import FooterDash from "./Dashboard/FooterDash";
import DashboardLayout from "./layouts/DashboardLayout";
import { mapState, mapActions } from "vuex";
import { GChart } from "vue-google-charts";

export default {
  components: {
    TopBarAdmin,
    SideBar,
    CardDash,
    // FooterDash,
    DashboardLayout,
    GChart,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.identifiedUser,
      isAdminUser: (state) => state.auth.isAdminUser,
      questionPerMonth: (state) => state.dash.questionPerMonth,
      usersMoreActives: (state) => state.dash.usersMoreActives,
      lvlQuestionPerMonth: (state) => state.dash.lvlQuestionPerMonth,
    }),
    chartOptionsBar() {
      if (!this.chartsLib) return null;
      return this.chartsLib.charts.Bar.convertOptions({
        chart: {
          title: "Preguntas mas respondidas por nivel",
        },
        bars: "horizontal", // Required for Material Bar Charts.
        hAxis: { format: "decimal" },
        height: 400,
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
      });
    },
  },
  data() {
    return {
      class_menu_admin: '',
      chartsLib: null, 
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartQuestions:[
         ["Mes", "Preguntas respondidas"],
      ],
      chartData: [
        ["Nombre", "Total"],
      ],
      chartDataBar: [
        ["Meses", "Fácil", "Intermedio", "Avanzado"],
      ],
      chartOptions: {
        chart: {
          title: "Preguntas mas respondidas por nivel"
        },
      },
    };
  },
  methods: {
    ...mapActions(["getQuestionsPermonths", "getUsersMoreActives", "getLvlQuestionsPermonths"]),
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
  },
  created() {
    if (!this.isAdminUser) {
      //Redirect to Game
      this.$router.push("/start");
    }
  },
  async mounted() {
    await this.getQuestionsPermonths();
    await this.getUsersMoreActives();
    await this.getLvlQuestionsPermonths();
    // console.log(this.lvlQuestionPerMonth);
    const months = [
      'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
    ];
    months.map((d, i) => {
      this.chartQuestions.push([d, this.questionPerMonth[i]?.total]);
    });
    
    this.usersMoreActives.map( d => {
      this.chartData.push([`${d.name} ${d.firstname}`, d.total]);
    });
    
    months.map((d, i) => {
      this.chartDataBar.push([d, 
        this.lvlQuestionPerMonth[i][0][0]?.ttl || 0,
        this.lvlQuestionPerMonth[i][1][0]?.ttl || 0,
        this.lvlQuestionPerMonth[i][1][0]?.ttl || 0
        ]);
    });
  },
};
</script>