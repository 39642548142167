// import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    questions: [],
    cats: [],
    question: {},
    options: ['A', 'B', 'C', 'D'],
    success: false,
    metaquestion:{
      lastpage:0
    },
    dataToCards: {},
    settingsGame:{
      id:0,
      initialMessage: '',
      timeForResponse:0,
      multiplyPoints:0
    },
    questionPerMonth:[],
    usersMoreActives:[],
    lvlQuestionPerMonth:[],
  },
  getters: { },
  mutations: {
    setQuestions(state, data) {
      state.questions = data;
    },
    setMetaQuestion(state, data){
      state.metaquestion.lastpage = data
    },
    setDetailQuestion(state, data) {
      state.question = data;
    },
    setCats(state, data) {
      state.cats = data;
    },
    setSuccess(state, data) {
      state.success = data;
    },
    setGameSettings(state, settings){
      state.settingsGame.id = settings.id;
      state.settingsGame.initialMessage = settings.initialMessage;
      state.settingsGame.timeForResponse = settings.timeforresponse;
      state.settingsGame.multiplyPoints = settings.multiplypoints;
    },
    setDataToCards(state, data) {
      state.dataToCards = data;
    },
    setQuestionPerMonth(state, data) {
      state.questionPerMonth = data;
    },
    setUsersMoreActives(state, data) {
      state.usersMoreActives = data;
    },
    setLvlQuestionPerMonth(state, data) {
      state.lvlQuestionPerMonth = data;
    },
  },
  actions: {
    async getQuestions(context, page){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/allquiz?page=${page}`)
      );
      
      if(!response.data.isError){
        context.commit('setMetaQuestion', response.data.data.last_page);
        context.commit('setQuestions', response.data.data.data);
      }else{
        context.commit('setQuestions', []);
      }
    },
    async getDetailQuestion(context, id = 0){
      // let idToEdit = id == 0 ? this.state.dash.idToEdit : id;
      let response = await context.getters.authenticatedAxios.get(`${apiUrl}/admin/quizdetail/${id}`);
      if(!response.data.isError){
        console.log(response.data.data[0]);
        context.commit('setDetailQuestion', response.data.data[0]);
      }else{
        context.commit('setDetailQuestion', {});
      }
    },
    async getSettingsGame(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/settings`)
      );
      if(!response.data.isError){
        context.commit('setGameSettings', response.data.data[0]);
        return true;
      }else{
        context.commit('setGameSettings', {initialMessage:'', timeforresponse: 0, multiplypoints: 0});
        return false;
      }
    },
    async updateSettingsGame(context, settings){
      let response = (
        await context.getters.authenticatedAxios.put(`${apiUrl}/admin/settings`, settings)
      );
      return !response.isError;
    },
    async getCats(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/category`)
      ).data;
      context.commit('setCats', response.data);
    },
    async updateQuizDetail(context, question){
      let response = (
        await context.getters.authenticatedAxios.put(`${apiUrl}/admin/quizdetail`, question)
      );
      let res = response.status === 201 ? true : false;
      context.commit('setSuccess', res);
      return res;
    },
    async addQuestions(context, questions) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/quizzesupload`, questions, { 
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest', 
        }
      });
      return response;
    },
    async formaddQuestionsUpload(context, questions) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/formquizzesupload`, questions, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
      });
      return response.status === 201 ? true : false;
    },
    async getDataInCards(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/generalInfo`)
      ).data;
      context.commit('setDataToCards', response.data);
    },
    async getQuestionsPermonths(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/getQuestionPerMonth`)
      ).data;
      // console.log(response.data)
      context.commit('setQuestionPerMonth', response.data);
    },
    async getUsersMoreActives(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/getUsersMoreActives`)
      ).data;
      context.commit('setUsersMoreActives', response.data);
    },
    async getLvlQuestionsPermonths(context){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/getLevelsPerMonth`)
      ).data;
      context.commit('setLvlQuestionPerMonth', response.data);
    }
  }
}