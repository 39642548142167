<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Carga masiva preguntas</h1>
        
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Preguntas</h2>
        <div class="additional-btn">
          
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content padding">
        <div class="drop">
          
        </div>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "../SideBar";
import TopBarAdmin from "../TopBarAdmin";
import DashboardLayout from "../../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    DashboardLayout,
    SideBar,
    TopBarAdmin,
  },
  computed: {
    ...mapState({
      detail: (state) => state.dash.question,
      categories: (state) => state.dash.cats,
      options: (state) => state.dash.options,
      success: (state) => state.dash.success,
    }),
   
  },
  data() {
    return {
      anwsToEdit: {}
    };
  },
  methods: {
    ...mapActions(['getDetailQuestion', 'getCats', 'updateQuizDetail']),
    async handleSaveChanges(){
      console.log(this.anwsToEdit);
      const res = await this.updateQuizDetail(this.anwsToEdit);
      if(res){
        this.$alert("Pregunta Actualizada.");
      }
    }
  },
  created(){
    // this.$store.dispatch('updateIdQuestionToEdit', this.$route.params.id)
    this.getCats();
    this.getDetailQuestion(this.$route.params.id);
  },
  mounted() { 
    this.anwsToEdit = Object.assign({}, this.detail);
    console.log(this.anwsToEdit);
  },
};
</script>