<template>
  <dashboard-layout>
    <template v-slot:TopBarContent>
      <top-bar-admin />
    </template>
    <template v-slot:SideBarContent>
      <side-bar></side-bar>
    </template>
    <template v-slot:TitleDashPage>
      <div class="page-heading">
        <h1><i class="fa fa-table"></i> Tokens actuales</h1>
        <h3>Listado</h3>
      </div>
    </template>
    <template v-slot:MainDashContent>
      <div class="widget-header transparent">
        <h2>Tokens</h2>
        <div class="additional-btn">
          <a href="#" class="hidden reload"><i class="icon-ccw-1"></i></a>
          <a href="#" class="widget-toggle"><i class="icon-down-open-2"></i></a>
          <a href="#" class="widget-close"><i class="icon-cancel-3"></i></a>
        </div>
      </div>
      <div class="widget-content">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Token</th>
                <th scope="col">Producto</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Total</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="q in tokens" :key="q.id">
                <td>{{ q.id }}</td>
                <td>{{ q.token }}</td>
                <td>{{ q.shopping_carts.product.name }}</td>
                <td>{{ q.shopping_carts.quantity }}</td>
                <td>${{ q.total}}</td>
                <td>
                  <a
                    @click.prevent="handleChangeToken(q.id,q.token)"
                    class="btn btn-primary mr-2"
                  >
                    <vue-fontawesome icon="exchange"></vue-fontawesome>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <paginate
          :page-count="pageCount"
          :click-handler="handleClickPage"
          :prev-text="'Anterior'"
          :next-text="'Siguiente'"
          :page-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
          :page-link-class="'page-link'"
          :container-class="'pagination'">
        </paginate>
      </div>
    </template>
  </dashboard-layout>
</template>

<script>
import SideBar from "./SideBar";
import TopBarAdmin from "./TopBarAdmin";
import DashboardLayout from "../layouts/DashboardLayout";
import { mapActions, mapState } from "vuex";
import Paginate from 'vuejs-paginate';

export default {
  components: {
    DashboardLayout,
    SideBar,  TopBarAdmin, Paginate
  },
  computed: {
    ...mapState({
      tokens: (state) => state.dashboardtokens.tokens,
      pageCount: (state) => state.dashboardtokens.metatoken.lastpage,
    }),
  },
  methods: {
    ...mapActions(["getTokens","updateTokenDetail"]),
    handleClickPage(pageNum){
      this.getTokens(pageNum);
    },
    handleChangeToken(token,info) {
      this.$fire({
        html: "¿Canjear el token? <br> ("+info+")",
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showCancelButton: true,
        reverseButtons: true,
        allowOutsideClick: false,
      }).then( async r => {
        if (r.value == true) {
          const updatetok = await this.updateTokenDetail({
            token: token,
            info: info,
          });
          if (!updatetok) {
            this.$fire({
              html: "Lo sentimos no se ha podido realizar el canje del token",
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false,
            }).then(() => this.$router.push('/dashboardtokens') );
          }else{
            this.$fire({
              html: "Canje del token realizado con éxito",
              confirmButtonText: 'Aceptar',
              allowOutsideClick: false,
            });
          }
          
        } else if (r.isDenied) {
          this.$close();
        }
      });
    },
  },
  mounted() {
    this.getTokens(1);
  },

};
</script>