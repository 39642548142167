<template>
  <div>
    <div class="sticky-top">
      <div class="container-fluid bg-white" >
        <div class="container-lg">
          <div class="row">
            <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <slot name="headerContent"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid px-0">
        <div class="container-lg">
          <div class="row">
            <div class="col px-0 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
              <slot name="titleContent"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid px-0" >
      <div class="container-lg ">
        <div class="row ">
          <div class="col px-0 offset-md-1 col-md-10 offset-lg-2 col-lg-8 no-gutters bg-card-0">
            <slot name="mainContent"></slot>
          </div>
        </div>
      </div>
    </div>
    <!-- Header component -->
  </div>
</template>
<script>
export default {
  props: {
    bgColorLevel: String
  },
}
//
// 
</script>