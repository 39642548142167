<template>
  <div class="overlay-foqui" :class="{ active: show }" >
    <div class="content-overlay">
      <div class="content-alert-foqui">
         <div class="content-close-foqui">
            <a @click="$emit('close')">
              <vue-fontawesome 
                class="ml-3" 
                icon="times" 
                size="4" 
                :style="{ color: '#003878' }">
              </vue-fontawesome>
            </a>
          </div>
        <h3>{{title}}</h3>
        <p>{{contentTxt}}</p>
      </div>
      <img src="../../assets/img/foqui.svg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show : Boolean,
    contentTxt : String,
    title: String
  },
  methods:{
   
  }
}
</script>
<style lang="scss">
.content-close-foqui{
  position: relative;
  a{
    position: absolute;
    right: 5px;
    top: -18px;
  }
}
.active{
  display: block !important;
}
.overlay-foqui{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  z-index: 2021; /* Specify a stack order in case you're using a different order for other elements */
}
.content-overlay{
  position: absolute;
  top: 50%;
  left: 50%;
  /* font-size: 50px; */
  color: white;
  text-align: center;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
</style>