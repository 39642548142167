<template>
  <nav
    class="navbar navbar-expand-lg bg-white text-uppercase fixed-top"
    v-bind:class="{ backwhite: show }"
    id="mainNav"
  >
    <div class="container">
      <a class="navbar-brand" href="#page-top"
        ><img src="../../assets/img/logo.svg" class="img-fluid"
      /></a>
      <button
        class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary-edit text-white rounded"
        type="button"
        data-toggle="collapse"
        data-target="#navbarResponsive"
        @click.stop="toggleNavbar()"
      >
        <i class="fa fa-bars"></i>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarResponsive"
        v-bind:class="{ show: show }"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1">
            <a
              class="nav-link py-3 px-0 px-lg-3 rounded"
              href="#begin" v-smooth-scroll
              >Inicio</a
            >
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <a
              class="nav-link py-3 px-0 px-lg-3 rounded"
              href="#features" v-smooth-scroll
              >Features</a
            >
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <a
              class="nav-link py-3 px-0 px-lg-3 rounded"
              href="#rules" v-smooth-scroll
              >Reglas del juego</a
            >
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <a
              class="nav-link py-3 px-0 px-lg-3 rounded"
              href="#top5" v-smooth-scroll
              >Top 5</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      show: false,
      backwhite: false,
    };
  },
  methods: {
    toggleNavbar() {
      this.show = !this.show;
      this.backwhite = !this.backwhite;
    },
  },
};
</script>
<style lang="scss">
.backwhite {
  background-color: white !important;
}
</style>