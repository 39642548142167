<template>
  <div class="container-fluid bg-login-novo px-0 vh-100" :class="bglayout">
    <div v-if="showHeadBand" class="headbandNovo bg-card-1">
      <h4> Usuario verificado, puedes ingresar con tu email y contraseña elegido. </h4>
    </div>
    <div class="container-lg">
      <div class="row">
        <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
          <slot name="mainContent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bglayout: String,
    showHeadBand: Boolean
  }
}
</script>
<style lang="scss">
@import "../../assets/css/colors";

.bgRegister{
   background: $color-background;
}
</style>