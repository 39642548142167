<template>
  <register-layout :bglayout="bgRegister">
    <template v-slot:mainContent>
      <form class="px-md-3 px-lg-4">
        <div class="mb-1 mt-5">
          <label for="username" class="form-label form-label-novo"
            >Nombre(s) <span class="obligatory">*</span></label
          >
          <input
            id="username"
            type="text"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="name"
            placeholder="Nombre"
          />
        </div>
        <div class="mb-3">
          <label for="lastname" class="form-label form-label-novo"
            >Apellidos <span class="obligatory">*</span></label
          >
          <input
            id="lastname"
            type="text"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="firstName"
            placeholder="Apellidos"
          />
        </div>
        <div class="mb-3">
          <label for="initials" class="form-label form-label-novo"
            >Iniciales <span class="obligatory">*</span></label
          >
          <input
            id="initials"
            type="text"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="initials"
            placeholder="Iniciales"
            @keydown="handleOnlyText"
            maxlength="3"
          />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label form-label-novo"
            >Correo electrónico <span class="obligatory">*</span></label
          >
          <input
            id="email"
            type="email"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="email"
            placeholder="Correo electrónico"
          />
        </div>
        <div class="mb-3">
          <label for="pass" class="form-label form-label-novo"
            >Contraseña <span class="obligatory">*</span></label
          >
          <input
            id="pass"
            type="password"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="password"
            placeholder="Contraseña"
          />
        </div>
        <div class="mb-3">
          <label for="samepass" class="form-label form-label-novo"
            >Repetir contraseña <span class="obligatory">*</span></label
          >
          <input
            id="samepass"
            type="password"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="repeatPass"
            placeholder="Repetir contraseña"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label form-label-novo">Teléfono</label>
          <input
            id="phone"
            type="tel"
            @keydown="handleOnlyNumber"
            maxlength="10"
            name=""
            class="form-control form-control-novo"
            value=""
            v-model="celphone"
            placeholder="Teléfono"
          />
        </div>
        <div class="mb-5">
          <div class="row">
            <div class="col d-flex flex-column align-items-center">
              <label for="sex" class="form-label form-label-novo mb-2"
                >Sexo</label
              >
              <div class="custom-radio-button">
                <div class="mr-2">
                  <input
                    type="radio"
                    id="color-red"
                    name="sex"
                    value="0"
                    v-model="sex"
                  />
                  <label for="color-red">
                    <span>M</span>
                  </label>
                </div>

                <div class="ml-2">
                  <input
                    type="radio"
                    id="color-blue"
                    name="sex"
                    value="1"
                    v-model="sex"
                  />
                  <label for="color-blue">
                    <span>H</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col d-flex flex-column align-items-center">
              <label for="age" class="form-label form-label-novo mb-2"
                >Edad</label
              >
              <input
                id="age"
                type="text"
                @keydown="handleOnlyNumber"
                maxlength="2"
                name=""
                class="form-control form-control-novo"
                value=""
                v-model="age"
                placeholder="Edad"
              />
            </div>
          </div>
        </div>
        <div class="d-flex flex-column my-5 login_container">
          <button
            type="button"
            @click="handleAddUser"
            name="button"
            class="btn btn-primary-novo"
          >
            Enviar
          </button>
          <router-link to="/login" class="btn mt-4 btn-secondary-novo"
            >Regresar</router-link
          >
        </div>
      </form>
      <loading-novo></loading-novo>
    </template>
  </register-layout>
</template>

<script>
import RegisterLayout from "./layouts/RegisterLayout";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import LoadingNovo from "./shared/Loading";

export default {
  components: {
    RegisterLayout,
    LoadingNovo,
  },
  data: () => {
    return {
      bgRegister: "bgRegister",
      name: "",
      firstName: "",
      initials: "",
      email: "",
      password: "",
      repeatPass: "",
      celphone: "",
      sex: -1,
      age: 0,
    };
  },
  validations: {
    name: { required },
    firstName: { required },
    initials: { required },
    email: { required, email},
    password: { required },
  },
  methods: {
    ...mapActions(["addNewUser", "setLoadingState"]),
    async handleAddUser() {
      const patterDomain = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(novonordisk)\.com$/;
      if(patterDomain.test(this.email)){
        if (!this.$v.$invalid) {
          if (this.password === this.repeatPass) {
            this.setLoadingState(true);
            const created = await this.addNewUser({
              name: this.name,
              firstName: this.firstName,
              initials: this.initials,
              email: this.email,
              password: this.password,
              phone: this.celphone,
              sex: this.sex,
              age: this.age,
            });
            this.setLoadingState(false);
            if (created) {
              this.$router.push("/successregister");
            } else {
              // this.showFailureMessage = true;
              this.$alert("No pudimos registrarte, verifica que no estés registrado previamente.");
              console.error("Algo falló");
            }
          } else {
            this.$alert("Las contraseñas deben ser iguales");
          }
        } else {
          this.$alert("Debes llenar los campos marcados con *.");
        }
      }else{
        this.$alert("Lo siento. Debes formar parte de la familia NovoNordisk ;)");
      }
    },
    handleOnlyNumber(event) {
      const char = String.fromCharCode(event.keyCode);
      if (event.keyCode != 8) {
        if (!/[0-9]/.test(char)) {
          event.preventDefault();
        }
      }
    },
    handleOnlyText(event) {
      const char = String.fromCharCode(event.keyCode);
      if (event.keyCode != 8) {
        if (!/[A-Z,a-z]/.test(char)) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/colors";

.custom-radio-button div {
  display: inline-block;
}
.custom-radio-button input[type="radio"] {
  display: none;
}
.custom-radio-button input[type="radio"] + label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.custom-radio-button input[type="radio"] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 44px;
}
.custom-radio-button input[type="radio"] + label span img {
  opacity: 0;
  transition: all 0.3s ease;
}
.custom-radio-button input[type="radio"]#color-red + label span {
  background-color: transparent;
  border: 1px solid $color-primary;
}
.custom-radio-button input[type="radio"]#color-blue + label span {
  background-color: transparent;
  border: 1px solid $color-primary;
}
.custom-radio-button input[type="radio"]#color-blue:checked + label span {
  background-color: $color-secondary !important;
}
.custom-radio-button input[type="radio"]#color-red:checked + label span {
  background-color: $color-card-1 !important;
}
.custom-radio-button input[type="radio"]:checked + label span {
  opacity: 1;

  color: $color-text-gray !important;
  display: inline-block;
}
</style>