// import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    products: [],
    product: {},
    success: false,
    metaproduct:{
      lastpage:0
    }
  },
  getters: { },
  mutations: {
    setProducts(state, data) {
      state.products = data;
    },
    setMetaProducts(state, data){
      state.metaproduct.lastpage = data
    },
    setDetailProduct(state, data) {
      state.product = data;
    },
    setSuccess(state, data) {
      state.success = data;
    },
  },
  actions: {
    async addProduct(context, product) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/product`, product, { 
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest', 
        }
      });
      return response.status === 201 ? true : false;
    },
    async getProducts(context, page){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/allproducts?page=${page}`)
      ).data;
      context.commit('setMetaProducts', response.last_page);
      context.commit('setProducts', response.data);
    },
    async getDetailProduct(context, id = 0){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/productdetail/${id}`)
      ).data;
      console.log(response);
      context.commit('setDetailProduct', response[0]);
    },
    async updateProductDetail(context, product){
      let response = (
        await context.getters.authenticatedAxios.post(`${apiUrl}/admin/productdetail`, product, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest', 
          }
        })
      );
      let res = response.status === 201 ? true : false;
      context.commit('setSuccess', res);
      return res;
    },
    async deletedProduct(context, product){
      await context.getters.authenticatedAxios.delete(`${apiUrl}/admin/productdelete/${product}`);
      context.dispatch("getProducts", 1);
    },

    async addProducts(context, products) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/productupload`, products, { 
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest', 
        }
      });
      return response;
    },
    async formaddProductsUpload(context, products) {
      let response = await context.getters.authenticatedAxios.post(`${apiUrl}/admin/formproductupload`, products, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        }
      });
      return response.status === 201 ? true : false;
    },

  }
}