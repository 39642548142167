<template>
  <div>
    <div class="container-fluid px-0 bg-primary-novo w-100">
      <header class="container-lg">
        <div class="row">
          <div class="col offset-md-2 col-md-8  offset-lg-4 col-lg-4">
            <slot name="titleContent">
               <h3 class="txt-color-white d-flex align-items-center px-2">Registro</h3>
            </slot>
          </div>
        </div>
      </header>
    </div>
    
    <div class="container-fluid" :class="bglayout">
      <div class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <slot name="mainContent"></slot>
          </div>
        </div>
      </div>
    </div>
    
    <div class="container-fluid px-0 w-100">
      <footer class="container-lg">
        <div class="row">
          <div class="col offset-md-1 col-md-10 offset-lg-2 col-lg-8 d-flex justify-content-around">
            <h4 class="txt-color-gray align-self-center">
              <router-link class="txt-color-gray" to="/privacity">Aviso de privacidad</router-link></h4>
            <img class="wsize-30" src="../../assets/img/logoInsulin.svg" alt="">
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bglayout: String
  }
}
</script>
<style lang="scss">
@import "../../assets/css/colors";

.bgRegister{
   background: $color-background;
}
.h8-vh{
  height: 8vh;
}
.wsize-30{
  width: 30%;
}
</style>