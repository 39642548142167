// import Axios from 'axios';
import {apiUrl} from '../env';

export default {
  state:{
    tokens: [],
    token: {},
    success: false,
    metatoken:{
      lastpage:0
    }
  },
  getters: { },
  mutations: {
    setTokens(state, data) {
      state.tokens = data;
    },
    setMetaTokens(state, data){
      state.metatoken.lastpage = data
    },
    setDetailToken(state, data) {
      state.token = data;
    },
    setSuccess(state, data) {
      state.success = data;
    },
  },
  actions: {
    async getTokens(context, page){
      let response = (
        await context.getters.authenticatedAxios.get(`${apiUrl}/admin/alltokens?page=${page}`)
      ).data;
      context.commit('setMetaTokens', response.last_page);
      context.commit('setTokens', response.data);
    },
    async updateTokenDetail(context, inf){
      let response = (
        await context.getters.authenticatedAxios.post(`${apiUrl}/admin/tokendetail`, inf)
      );
      let res = response.status === 201 ? true : false;
      context.commit('setSuccess', res);
      context.dispatch("getTokens", 1);
      return res;
    },

  }
}